import _ from "lodash";
import moment from "moment";

export function getPathPrefix(endPath = "") {
  const companyDomain = localStorage.getItem("companyDomain");
  const langCode = localStorage.getItem("languageCode") ?? "en";
  if (!companyDomain || !langCode) {
    return "/";
  }

  return "/" + companyDomain + "/" + langCode + "/" + endPath;
}

/**
 * Check if path is only `/domain/langCode/` then return true else false.
 * @returns boolean
 */
export function isInitialPath(path) {
  let prefixPath = getPathPrefix().toLowerCase();

  if (prefixPath[prefixPath.length - 1] === "/") {
    prefixPath = prefixPath.substring(0, prefixPath.length - 1);
  }

  if (path[path.length - 1] === "/") {
    path = path.substring(0, path.length - 1);
  }

  // console.log(prefixPath, path);

  return path === prefixPath;
}

export function loadSideBarJs() {
  const oldScript = document.getElementById("mainJs");
  if (oldScript) {
    oldScript.remove();
  }
  const script = document.createElement("script");
  script.src = "/assets/js/sidebar.js";
  script.async = true;
  script.id = "mainJs";

  document.body.appendChild(script);
}

export const convertBase64 = (r) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(r);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const joinClassNames = (classList) => {
  if (!classList) return "";
  if (typeof classList === "string") {
    return classList;
  }
  return classList.filter((e) => e).join(" ");
};

export const CRUD_RULE_ENGINE_TYPES = {
  CREATE: "create",
  VIEW: "view",
  EDIT: "edit",
};

export const CRUD_GEOGRAPHICAL_TYPES = {
  CREATE: "create",
  VIEW: "view",
  EDIT: "edit",
};

export function nameCapitalize(original) {
  if (!original) return original;

  /* const words = original.split(" ");

  return words
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1).toLowerCase();
    })
    .join(" "); */

  return original.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
    letter.toUpperCase()
  );
}

export function formatDateToMyFormat(date, format) {
  if (!moment(date).isValid()) return date;

  return moment(date).format(format ?? "DD/MM/YYYY");
}

export function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export function groupByJson(items, key) {
  return items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );
}
export function getParentPathBySplit(str, splitBy = ".") {
  if (!str || typeof str !== "string") return str;

  const splitted = str.split(splitBy);
  splitted.pop();
  return splitted.join(splitBy);
}

export function getLastBySplit(str, splitBy = ".") {
  if (!str || typeof str !== "string") return str;

  const splitted = str.split(splitBy);
  return splitted[splitted.length - 1];
}

export function getFieldsList(parent, selectedChild, mappedJson) {
  if (!parent || !selectedChild) {
    return [];
  }

  const finalPath = selectedChild.replace(parent + ".", "");

  const allChildStrings = finalPath.split(".");

  const fieldList = [];

  while (allChildStrings.length > 0) {
    fieldList.push(mappedJson[parent + "." + allChildStrings.join(".")]);
    allChildStrings.pop();
  }

  return fieldList.filter((e) => Boolean(e)).reverse();
}

export function joinStringArray(
  stringArray = [],
  joinBy = ".",
  noFilter = false
) {
  return stringArray.filter((e) => (!noFilter ? e : true)).join(joinBy);
}

export function getTypeForcefully(value) {
  if (value === "") {
    return undefined;
  }

  if (value === "true" || value === "false" || typeof value === "boolean") {
    return "boolean";
  }

  if (!isNaN(value)) {
    return "number";
  }

  if (moment(value, true).isValid()) {
    return "date";
  }

  return typeof value;
}

export function getWidgetTypeBasedOnFieldType(type) {
  if (type === "string") {
    return "text";
  }

  if (type === "date") {
    return "datetime";
  }

  return type ?? "any";
}

export function getWidgetTypeBasedOnFieldTypeNoAny(type) {
  if (type === "string") {
    return "text";
  }

  if (type === "datetime") {
    return "date";
  }

  return type;
}

export function groupSelectOptionsStartWith(
  list = [],
  startsWith = "",
  groupLabel = ""
) {
  const finalJson = list.reduce(
    (result, item) => {
      const opt = [...result.options];

      if (item.path.startsWith(startsWith)) {
        opt.push(item);
      }

      return {
        label: groupLabel,
        options: opt,
      };
    },
    {
      options: [],
    }
  );
  return finalJson.options.length > 0
    ? {
      ...finalJson,
      options: finalJson.options.map((item) => ({
        ...item,
        value: item.path,
      })),
    }
    : undefined;
}

export function truncateString(str, limit = 30) {
  if (str.length > limit) {
    return str.slice(0, limit) + "...";
  } else {
    return str;
  }
}

export const getUUID = () => window.crypto.randomUUID();

export const camelizeObject = obj => _.transform(obj, (acc, value, key, target) => {
  const camelKey = _.isArray(target) ? key : _.camelCase(key);
  acc[camelKey] = _.isObject(value) ? camelizeObject(value) : value;
});

export function readMyFile(file) {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject("File not selected");
      return;
    }
    const reader = new FileReader();
    reader.onloadend = async (e) => {
      const text = e.target.result;
      resolve(text);
      // props.setFieldValue(jsonText, text);
    };
    reader.readAsText(file);
  });
}

export function replaceValuesWithBlank(json) {
  if (!json || typeof json !== "object") return json;
  const copy = JSON.parse(JSON.stringify(json));

  for (const key in copy) {
    if (copy.hasOwnProperty(key)) {
      if (typeof copy[key] === "object") {
        copy[key] = replaceValuesWithBlank(copy[key]);
      } else if (typeof copy[key] === "string" && copy[key].length > 200) {
        copy[key] = copy[key].substring(0, 200) + "-content_trimmed";
      }
      /* copy[key] =
        typeof copy[key] === "object"
          ? replaceValuesWithBlank(copy[key])
          : typeof copy[key] === "string" && copy[key].length > 100
          ? copy[key].subString(0, 100) + "-content_trimmed"
          : copy[key]; */
    }
  }
  return copy;
}

export function getFinalJsonWithKeyValue(arr) {
  const finalJson = {}

  arr.forEach(field => {
    for (const key in field) {
      finalJson[key] = field[key];
    }
  })

  return finalJson;
}