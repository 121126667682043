import React from "react";

const Input = ({
  value,
  onChange,
  icon = "",
  label,
  placeholder = "",
  m2 = false,
  textCenter = false,
  maxlength = "100",
  type,
  ref = null,
  autoComplete,
  inputMode,
  onKeyUp,
  tabIndex,
  onMouseUp,
  onMouseDown,
  onClick,
}) => {
  return (
    <div className="form-group">
      {label && <label className="input-label">{label}</label>}
      <input
        type={type}
        className={
          m2 && textCenter ? "m-2 text-center form-control " : "form-control"
        }
        placeholder={placeholder}
        required="required"
        value={value}
        onChange={onChange}
        maxlength={maxlength}
        ref={ref}
        autoComplete={autoComplete}
        inputMode={inputMode}
        onKeyUp={onKeyUp}
        tabIndex={tabIndex}
      />
      {icon && (
        <div
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          className="login-icon"
          onClick={onClick}
        >
          <i className={icon}></i>
        </div>
      )}
    </div>
  );
};

export default Input;
