const menuReducerTypes = {
  SELECT_DATA_STREAM: "select_data_stream",
};

const menuStore = {
  selectedDataStream: undefined,
};

export default function menuReducer(state = menuStore, action) {
  switch (action.type) {
    case menuReducerTypes.SELECT_DATA_STREAM:
      console.log(menuReducerTypes.SELECT_DATA_STREAM, action.payload);
      return {
        ...state,
        selectedDataStream: action.payload,
      };
    default:
      return state;
  }
}

export { menuReducerTypes };
