import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DangerButton from "../Form/Buttons/DangerButton";
import PrimaryButton from "../Form/Buttons/PrimaryButton";

const CustomModal = ({
  title,
  body,
  customButtons,
  show,
  handleClose,
  okTitle,
  onOk,
  cancelTitle,
  onCancel,
  centered,
  centerFooter,
  size,
  backdropClassName,
  submitDisabled,
}) => {
  const [t] = useTranslation();
  return (
    <Modal
      show={show}
      onHide={handleClose}
      keyboard={true}
      onBackdropClick={handleClose}
      dialogClassName="pt-0"
      contentClassName="rounded-10"
      backdropClassName={backdropClassName}
      centered={centered}
      size={size}
    >
      {title && (
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}

      <Modal.Body>{body}</Modal.Body>

      {customButtons ? (
        <Modal.Footer>{customButtons}</Modal.Footer>
      ) : (
        <>
          {(onOk || onCancel) && (
            <Modal.Footer
              className={`${centerFooter ? "justify-content-center" : ""}`}
            >
              <div>
                {onOk && (
                  <PrimaryButton
                    title={okTitle ?? t("CommonKeys.okButtonTitle")}
                    onClick={onOk}
                    className={"me-3"}
                    disabled={submitDisabled}
                  />
                )}
                {onCancel && (
                  <DangerButton
                    title={cancelTitle ?? t("CommonKeys.cancelButtonTitle")}
                    onClick={onCancel}
                    className
                  />
                )}
              </div>
            </Modal.Footer>
          )}
        </>
      )}
    </Modal>
  );
};

export default CustomModal;
