import { Form } from "react-bootstrap";

export default function BasicTextInput({
  label,
  onChange,
  onBlur,
  value,
  type,
  isRequired,
  disabled,
  formLabelClass,
  groupProps: { controlId, ...otherGroupProps },
  inputProps: { ...otherInputProps },
  ...props
}) {
  return (
    <Form.Group controlId={controlId} {...otherGroupProps}>
      {label && (
        <Form.Label {...formLabelClass}>
          {label} {isRequired && <span style={{ color: "red" }}>*</span>}
        </Form.Label>
      )}

      <Form.Control
        type={type || "text"}
        {...otherInputProps}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
      />
    </Form.Group>
  );
}
