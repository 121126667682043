// @flow
import * as React from "react";

export const ConfirmationModal = ({
  onYesClicked,
  onNoClicked,
  modalId,
  title,
  singleButton,
}) => {
  return (
    <div className="modal fade" id={modalId} tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content" style={{ borderRadius: 10 }}>
          <div className="modal-header d-flex align-items-center justify-content-center">
            <h5
              className="modal-title text-center"
              id="exampleModalLabel"
              style={{ color: "#004e5c", maxWidth: "400px" }}
              dangerouslySetInnerHTML={{ __html: title }}
            ></h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="modal-body">
            {!singleButton ? (
              <div className="justify-content-center row">
                <div className="w-auto">
                  <button
                    href="#"
                    type="button"
                    className="btn"
                    style={{
                      fontWeight: 600,
                    }}
                    data-bs-dismiss="modal"
                    onClick={onYesClicked}
                  >
                    Yes
                  </button>
                </div>
                <div className="w-auto">
                  <button
                    href="#"
                    type="button"
                    style={{
                      fontWeight: 600,
                    }}
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                    onClick={onNoClicked}
                  >
                    No
                  </button>
                </div>
              </div>
            ) : (
              <div className="justify-content-center row">
                <div className="w-auto">
                  <button
                    href="#"
                    type="button"
                    style={{
                      fontWeight: 600,
                    }}
                    className="btn"
                    data-bs-dismiss="modal"
                    onClick={onNoClicked}
                  >
                    Okay
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="bottom-border"></div>
        </div>
      </div>
    </div>
  );
};
