import languageTypes from "../actionTypes";

const langStore = {
  languageCodeData: undefined,
  isLoading: false,
};

export function langReducer(state = langStore, action) {
  switch (action.type) {
    case languageTypes.LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case languageTypes.LANGUAGE:
      return {
        ...state,
        languageCodeData: action.payload.languageCodeData,
      };
    default:
      return { ...state };
  }
}
