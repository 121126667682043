import { createRef, forwardRef, useImperativeHandle } from "react";
import ReactSelect from "react-select";

const AICCFieldSelector = forwardRef(
  ({ columnList = [], value, onChange, placeholder, noOptionsLabel }, ref) => {
    const selectRef = createRef();

    const styles = {
      menu: (base) => ({
        ...base,
        width: "max-content",
        minWidth: "100%",
      }),
    };

    useImperativeHandle(
      ref,
      () => ({
        clearSelect: () => {
          if (selectRef?.current) {
            selectRef.current.clearValue();
          }
        },
      }),
      [selectRef]
    );

    /* useEffect(() => {
    const timeout = setTimeout(() => {
      if (!value) {
        selectRef?.current?.clearValue();
      }
    }, 100);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [selectRef, value]); */

    return (
      <div className="form-group mb-0">
        <ReactSelect
          ref={selectRef}
          styles={styles}
          // onMenuOpen={onMenuOpen}
          value={columnList.find((e) => "" + e.value === "" + value)}
          isOptionDisabled={(option) =>
            option.type
              ? ["array", "object"].includes(option.type)
              : option.type === ""
          }
          classNamePrefix={"select"}
          placeholder={placeholder ?? "Select Field"}
          noOptionsMessage={() => noOptionsLabel}
          options={columnList}
          onChange={onChange}
          menuPosition={"fixed"}
        />
      </div>
    );
  }
);

export default AICCFieldSelector;
