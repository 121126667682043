/* eslint-disable import/no-anonymous-default-export */
import { jsonToQuery } from "../../utils/ServicesUtils";
import { doFetch, REQUEST_CONTENT_TYPE, REQUEST_METHODS } from "../fetcher";

export default {
  getRoutingUser: (query) =>
    doFetch(`/route-user/${jsonToQuery(query)}`, {
      method: REQUEST_METHODS.GET,
    }),
  getRoutingUserDetails: (userId, query) =>
    doFetch(`/route-user/${userId}${jsonToQuery(query)}`, {
      method: REQUEST_METHODS.GET,
    }),
  saveRoutingUser: (body) =>
    doFetch(`/route-user/`, {
      body,
      method: REQUEST_METHODS.POST,
      // contentType: REQUEST_CONTENT_TYPE.MULTIPART,
    }),
  updateRoutingUser: (query, body) =>
    doFetch(`/route-user/${jsonToQuery(query)}`, {
      body,
      method: REQUEST_METHODS.PUT,
      // contentType: REQUEST_CONTENT_TYPE.MULTIPART,
    }),
  updateStatusOrDelete: (status, userId) =>
    doFetch(`/route-user/${status}/${userId}`, {
      body: {},
      method: REQUEST_METHODS.PUT,
      // contentType: REQUEST_CONTENT_TYPE.MULTIPART,
    }),
  getAllRouteUserByDataStreamConfigId: (query) =>
    doFetch(
      `/route-user/getAllRouteUserByDataStreamConfigId${jsonToQuery(query)}`,
      {
        method: REQUEST_METHODS.GET,
      }
    ),
};
