import React from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import AiccBreadCrumb from "../../components/common/AiccBreadCrumb";
import PrimaryButton from "../../components/Form/Buttons/PrimaryButton";
import ConfirmActivateModal from "../../components/Modals/ConfirmActivateModal";
import CreateRuleSettingTab, {
  RULE_MESSAGE_TYPE,
} from "../../components/ruleEngine/CreateRuleSettingTab";
import routeUserApi from "../../services/apis/routeUserApi";
import rulesApi from "../../services/apis/rulesApi";
import {
  CRUD_RULE_ENGINE_TYPES,
  getPathPrefix,
  nameCapitalize,
} from "../../utils/coreUtils";
import ToastUtils from "../../utils/ToastUtils";
import StorageManager from "../library/utils/storageManager";
import "../../assets/scss/queryBuilder.scss";
import CenterLoading from "../../components/common/CenterLoading";
import RuleEngineConvert from "../../utils/converter/RuleEngineConvert";
import RuleBuilderConvert from "../../utils/converter/RuleBuilderConvert";
import CusRuleBuilder from "./CusRuleBuilder";
import { createRef } from "react";
import { QueryContextProvider } from "./context/RuleEndingProvider";

const AddRuleEngine = () => {
  const cusRuleBuilderRef = createRef();

  const navigate = useNavigate();
  const params = useParams();
  const [t, i18n] = useTranslation();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("t");
  const [settingState, setSettingState] = useState({
    ruleName: "",
    groupName: "",
    ruleType: "",
    ruleTypeSelect: "",
    ruleTypeSelectId: "",
    priority: "",
    maxPriority: "",
    queryTree: "",
    sqlWhere: "",
    isActive: true,
    messageType: RULE_MESSAGE_TYPE.INTERNAL,
    order: 1,
    message: "",
    message1: "",
    email: "",
    emailMessage: "",
    emailDynamicMessage: "",
    internalDynamic: "static",
    isDynamic1: "static",
    isDynamic2: "static",
    isDynamic3: "static",
    emailDynamic: "static",
  });
  const [rulesTypesData, setRulesTypesData] = useState([]);
  const [getAllRouteUser, setAllRouteUser] = useState([]);
  const [prioritySame, setPrioritySame] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ruleOverviewDropdown, setRuleOverviewDropdown] = useState([]);
  const [errors, setErrors] = useState({});
  const [tempPriority, setTempPriority] = useState("");

  const selectedDataStream = StorageManager.getSelectedDataStream();

  const streamObj = selectedDataStream
    ? JSON.parse(selectedDataStream)
    : undefined;

  const breadCrumbTitle = useMemo(() => {
    if (type === CRUD_RULE_ENGINE_TYPES.CREATE) {
      return t("versionsForm.createTitle");
    }
    if (type === CRUD_RULE_ENGINE_TYPES.EDIT) {
      return t("versionsForm.updateTitle");
    }
    if (type === CRUD_RULE_ENGINE_TYPES.VIEW) {
      return t("versionsForm.viewTitle");
    }

    return t("versionsForm.createTitle");
  }, [searchParams]);

  useEffect(() => {
    getAllRuleTypes();
    getAllRouteUserByDataStreamConfigId();
    getAllGroupByName();
    getNextPriorityForRules();
    if (
      type === CRUD_RULE_ENGINE_TYPES.EDIT ||
      type === CRUD_RULE_ENGINE_TYPES.VIEW
    ) {
      fetchRuleDetails();
    }
  }, [type]);

  async function getAllRuleTypes() {
    // setIsLoading(true);
    try {
      const response = await rulesApi.getAllRulesType();
      setRulesTypesData(response?.data);
    } catch (error) {
      console.error(error);
    }
    // setIsLoading(false);
  }

  async function getNextPriorityForRules() {
    // setIsLoading(true);
    try {
      const response = await rulesApi.getNextPriorityForRules({
        dsConfigVersionId: params?.versionId,
      });
      // console.log("response", response?.data);
      // setRulesTypesData(response?.data);
      // settingState.priority = response?.data?.nextPriority;
      setSettingState((prev) => ({
        ...prev,
        priority: response?.data?.nextPriority,
        maxPriority: response?.data?.nextPriority,
      }));
      /* if (type === CRUD_RULE_ENGINE_TYPES.CREATE) {
       
      } else {
        settingState.maxPriority = response?.data?.nextPriority;
        settingState.priority = response?.data?.nextPriority;
        setSettingState({ ...settingState });
      } */
    } catch (error) {
      console.error(error);
    }
    // setIsLoading(false);
  }

  async function getAllRouteUserByDataStreamConfigId() {
    // setIsLoading(true);
    try {
      const response = await routeUserApi.getAllRouteUserByDataStreamConfigId({
        dataStreamConfigId: streamObj.dataStreamConfigId,
      });
      setAllRouteUser(response.data);
    } catch (error) {
      console.error(error);
    }
    // setIsLoading(false);
  }

  async function getAllGroupByName() {
    // setIsLoading(true);
    try {
      const response = await rulesApi.getGroupNameByGroup({
        dsConfigVersionId: params?.versionId,
      });
      setRuleOverviewDropdown(
        response.data.map((e, index) => ({
          label: nameCapitalize(e),
          value: index,
        }))
      );
    } catch (error) {
      console.error(error);
    }
    // setIsLoading(false);
  }

  //for error handling
  const updateError = (key, value) => {
    setErrors((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const updateErrorForInputs = (
    inputValue,
    minValue,
    inputKey,
    error,
    min,
    max
  ) => {
    let msg = "";
    if (!inputValue) {
      updateError(inputKey, error);
      setSubmitDisabled(true);
    } else if (min && parseInt(inputValue) < parseInt(min)) {
      // msg = `${inputKey} must be at least ${min} characters long.`;
      msg = `Priority should be greater than 0 and less then or equal to ${max}`;
      updateError(inputKey, msg);
      setSubmitDisabled(true);
    } else if (max && parseInt(inputValue) > parseInt(max)) {
      // msg = `${inputKey} is more than ${max} characters long.`;
      msg = `Priority should be greater than 0 and less then or equal to ${max}`;
      updateError(inputKey, msg);
      setSubmitDisabled(true);
    } else {
      updateError(inputKey, undefined);
      setSubmitDisabled(false);
    }
  };

  const buttonDisabled = useMemo(() => {
    if (
      Object.keys(errors).filter((e) => errors[e]).length > 0 ||
      !settingState.ruleName
    ) {
      return true;
    } else if (
      Object.keys(errors).filter((e) => errors[e]).length > 0 ||
      !settingState.groupName
    ) {
      return true;
    } else if (
      Object.keys(errors).filter((e) => errors[e]).length > 0 ||
      !settingState.priority
    ) {
      return true;
    } else if (
      Object.keys(errors).filter((e) => errors[e]).length > 0 ||
      !settingState.ruleTypeSelectId
    ) {
      return true;
    } /* else if (
      settingState.messageType == RULE_MESSAGE_TYPE.INTERNAL &&
      settingState.message.trim().length <= 0
    ) {
      return true;
    } else if (
      settingState.messageType == RULE_MESSAGE_TYPE.EXTERNAL &&
      settingState.message1.trim().length <= 0
    ) {
      return true;
    } else if (
      settingState.messageType == RULE_MESSAGE_TYPE.EPOST &&
      (settingState.email.trim().length <= 0 ||
        settingState.emailMessage.trim().length <= 0)
    ) {
      return true;
    } */ else {
      return false;
    }
  }, [errors, settingState]);

  const fetchRuleDetails = async () => {
    setIsLoading(true);
    try {
      const response = await rulesApi.getRulesById({
        rulesId: params.overviewId,
      });
      settingState.groupName = response?.data?.groupName;
      settingState.ruleName = response?.data?.name;
      settingState.priority = response?.data?.priority;
      settingState.isActive = response?.data?.isActive;
      // settingState.ruleType=""
      settingState.routingUserId = response?.data?.routingUserId;
      settingState.ruleTypeSelectId = response?.data?.rulesTypeId;
      settingState.others = response?.data;

      setSettingState({
        ...settingState,
        ...RuleEngineConvert.resMessageArrayToJson(response?.data?.messages),
      });

      setTempPriority(response?.data?.priority);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const onSubmitHandler = async (isOverrite) => {
    /* console.log(
      RuleBuilderConvert.aiccRBQueryToWhereClause(
        cusRuleBuilderRef.current?.getQuery()
      )
    ); */
    /* return; */
    // console.log("rulesTypesData", settingState?.routingUserId, rulesTypesData);

    let getManualRouting = rulesTypesData?.find(
      (elm) => elm?.label?.toLowerCase() === "manual routing"
    );
    let getManual = rulesTypesData?.find(
      (elm) => elm?.rulesTypeId === settingState?.ruleTypeSelectId
    );
    let selectedRuleTypeId = "";
    if (getManual && settingState?.routingUserId !== null) {
      selectedRuleTypeId = getManualRouting?.rulesTypeId;
    } else if (settingState?.routingUserId === null) {
      selectedRuleTypeId = settingState?.ruleTypeSelectId;
    }

    const createRequest = {
      dsConfigVersionId: params?.versionId,
      isDeleted: false,
      name: settingState?.ruleName,
      isActive: settingState?.isActive ?? true,
      queryString: "",
      routingUserId: settingState?.routingUserId
        ? settingState?.routingUserId
        : null,
      rulesGroupId: 0,
      rulesId:
        type === CRUD_RULE_ENGINE_TYPES.EDIT ? settingState.others.rulesId : 0,
      rulesOrder: "",
      groupName: settingState?.groupName,
      priority: settingState?.isActive ? settingState.priority : -1,
      rulesTypeId: selectedRuleTypeId,
      queryTree: settingState.queryTree,
      sqlWhere: settingState.sqlWhere,
      // getManual && settingState?.routingUserId === null
      //   ? getManualRouting?.rulesTypeId
      //   : settingState?.ruleTypeSelectId,
      messageList:
        RuleEngineConvert.getMessageDetailsBasedOnSelection(settingState),
      // ...RuleEngineConvert.getMessageDetailsBasedOnSelection(settingState),
      isMessageActive: true,
      whereClauses: RuleBuilderConvert.aiccRBQueryToWhereClause(
        cusRuleBuilderRef.current?.getQuery()
      ),
    };

    if (typeof isOverrite === "boolean" && isOverrite) {
      createRequest["isOverrite"] = isOverrite;
    }

    setIsSubmitting(true);
    try {
      const response = await rulesApi.saveOrUpdateRules(createRequest);
      if (response.status === 200) {
        if (response.data.status != 200) {
          ToastUtils.serverError(t(`statusCodes.${response?.data?.code}`));
        } else {
          toast.success(t(`statusCodes.${response?.data?.code}`));
        }
        navigate(-1);
        setTempPriority("");
      } else {
        // toast.error(t("versionsForm.errorMessage.ruleSaveFailure"));
      }
    } catch (error) {
      if (error.response.data.isDSConfigVersionPriorityAlready) {
        setPrioritySame(error.response.data.isDSConfigVersionPriorityAlready);
      } else if (error.response.data.status != 200) {
        ToastUtils.serverError(t(`statusCodes.${error.response.data.code}`));
      }
      toast.error(error.response?.data);
    }
    setIsSubmitting(false);
  };

  return (
    <QueryContextProvider>
      <div>
        <ConfirmActivateModal
          show={prioritySame}
          onOk={() => {
            onSubmitHandler(true);
            setPrioritySame(false);
          }}
          onClose={() => {
            setPrioritySame(false);
          }}
          messageTitles={t("createRuleEngine.modalErrorMessage")}
        />
        <div className="container-scroller">
          {/* <!-- partial --> */}
          <div className="container-fluid page-body-wrapper p-0">
            {/* <!-- partial --> */}
            <div className="main-panel">
              <div className="content-wrapper">
                <AiccBreadCrumb
                  homeLink={getPathPrefix(
                    `selected-datastream/rule-engine/versions/`
                  )}
                  title={streamObj?.customDataStreamName}
                  items={t("createRuleEngine.breadCrumb.items", {
                    returnObjects: true,
                  })}
                />
                <div className="col-12">
                  <div className="card main-card-table">
                    <div className="card-header">
                      <div className="row align-items-center">
                        <div className="col-12 col-md-4">
                          <div className="card-label">
                            <img
                              src="/assets/images/engine.svg"
                              alt=""
                              className="me-2"
                            />
                            {t("createRuleEngine.cardTitle")} - {breadCrumbTitle}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body add-rule-card">
                      {isLoading ? (
                        <CenterLoading />
                      ) : (
                        <div className="row">

                          <Tab.Container
                            id="v-pills-tab"
                            defaultActiveKey={
                            /* type === CRUD_RULE_ENGINE_TYPES.EDIT
                              ? "setting"
                              :  */ "setting"
                            }
                          >
                            {/* <div className="col-xl-9 col-xxl-10"> */}
                            <div className="col-xl-12">
                              <div className="col-xl-12">
                                <Nav
                                  variant="pills"
                                  className="nav-pills-custom justify-content-md-center justify-content-lg-start"
                                >
                                  <Nav.Item>
                                    <Nav.Link eventKey="setting">
                                      <i className="fa-solid fa-circle-dot me-2"></i>
                                      {t("createRuleEngine.topTab.settings")}
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="rule-creation">
                                      <i className="fa-solid fa-circle-dot me-2"></i>
                                      {t("createRuleEngine.topTab.ruleCreation")}
                                    </Nav.Link>
                                  </Nav.Item>
                                </Nav>
                              </div>
                              <div className="col-xl-12">
                                <Tab.Content id="v-pills-tabContent">
                                  <Tab.Pane
                                    eventKey="rule-creation"
                                    className="rounded-container"
                                  >
                                    <CusRuleBuilder
                                      ref={cusRuleBuilderRef}
                                      overviewId={params.overviewId}
                                      viewOnly={
                                        type === CRUD_RULE_ENGINE_TYPES.VIEW
                                      }
                                    />
                                  </Tab.Pane>

                                  <Tab.Pane eventKey="setting">
                                    <CreateRuleSettingTab
                                      settingState={settingState}
                                      setSettingState={setSettingState}
                                      rulesTypesData={rulesTypesData}
                                      getAllRouteUser={getAllRouteUser}
                                      isEdit={
                                        type === CRUD_RULE_ENGINE_TYPES.EDIT
                                      }
                                      viewOnly={
                                        type === CRUD_RULE_ENGINE_TYPES.VIEW
                                      }
                                      created={
                                        type === CRUD_RULE_ENGINE_TYPES.CREATE
                                      }
                                      errors={errors}
                                      setErrors={setErrors}
                                      updateError={updateError}
                                      updateErrorForInputs={updateErrorForInputs}
                                      ruleOverviewDropdown={ruleOverviewDropdown}
                                      setRuleOverviewDropdown={
                                        setRuleOverviewDropdown
                                      }
                                      getNextPriorityForRules={
                                        getNextPriorityForRules
                                      }
                                      tempPriority={tempPriority}
                                    />
                                  </Tab.Pane>
                                </Tab.Content>
                              </div>
                            </div>
                          </Tab.Container>
                          <div className="text-end mt-4">
                            <PrimaryButton
                              title={t("CommonKeys.backButtonTitle")}
                              icon="fa-solid fa-arrow-left"
                              onClick={() => {
                                navigate(-1);
                                /* navigate(
                                getPathPrefix(
                                  `selected-datastream/rule-engine/versionsOverview/${params?.versionId}`
                                )
                              ); */
                              }}
                            />
                            {!(type === CRUD_RULE_ENGINE_TYPES.VIEW) && (
                              <PrimaryButton
                                title={t("CommonKeys.saveButtonTitle")}
                                icon="fa-regular fa-floppy-disk"
                                className="ms-3"
                                onClick={() => {
                                  onSubmitHandler();
                                }}
                                disabled={buttonDisabled || isSubmitting}
                              />
                            )}

                            {/* <a
                          href="#"
                          type="button"
                          className="btn"
                          data-bs-toggle="modal"
                          data-bs-target="#saveModal"
                        >
                          <i className="fa-regular fa-floppy-disk me-2"></i>
                          Save
                        </a> */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- main-panel ends --> */}
          </div>
          {/* <!-- page-body-wrapper ends --> */}
        </div>
        {/* <!-- container-scroller --> */}
      </div>
    </QueryContextProvider>
  );
};

export default AddRuleEngine;
