export function jsonToQuery(json, encode = false) {
  if (!json) return "";

  const queryArray = [];

  Object.keys(json).forEach((e) => {
    queryArray.push(
      `${e}=${
        typeof json[e] === "string"
          ? encode
            ? encodeURIComponent(json[e].trim())
            : json[e].trim()
          : json[e]
      }`
    );
  });

  return queryArray.length > 0 ? "?" + queryArray.join("&") : "";
}

export function jsonToFormData(json, avoidUndefined = true) {
  if (!json) return "";

  const formData = new FormData();

  Object.keys(json).forEach((e) => {
    if (avoidUndefined && json[e] === undefined) return;

    formData.append(e, json[e]);
  });

  return formData;
}
