/* eslint-disable import/no-anonymous-default-export */
import { jsonToQuery } from "../../utils/ServicesUtils";
import { doFetch, REQUEST_METHODS, REQUEST_CONTENT_TYPE } from "../fetcher";

export default {
  generateAccessToken: (query) =>
    doFetch(`/openApi/generateAccessToken${jsonToQuery(query)}`, {
      isAdmin: true,
      method: REQUEST_METHODS.POST,
    }),
  getCompanyInformationByDomainName: (query) =>
    doFetch(`/openApi/getCompanyInformationByDomainName${jsonToQuery(query)}`, {
      isAdmin: true,
      method: REQUEST_METHODS.GET,
    }),
  getCompanyAccessDetail: (body) =>
    doFetch(`/openApi/getCompanyAccessDetail`, {
      body,
      isAdmin: true,
      method: REQUEST_METHODS.POST,
      showToast: false,
    }),
  getDataStreamConfig: (query) =>
    doFetch(`/openApi/getDataStreamConfigByCompany${jsonToQuery(query)}`, {
      isAdmin: true,
      useAdminAuth: true,
      method: REQUEST_METHODS.GET,
    }),
  getAllAggregateFunction: (query) =>
    doFetch(`/openApi/getAllAggregateFunction${jsonToQuery(query)}`, {
      isAdmin: true,
      method: REQUEST_METHODS.GET,
    }),
  getAllRulesBySubKey: (query) =>
    doFetch(`/openApi/aiccPayloadProccesing`, {
      isAdmin: true,
      body: query,
      method: REQUEST_METHODS.POST,
      contentType: REQUEST_CONTENT_TYPE.MULTIPART,
    }),

  getCustomFieldsByDataStreamConfigIdAndSchemaId: (query) =>
    doFetch(
      `/openApi/getCustomFieldsByDataStreamConfigIdAndSchemaId${jsonToQuery(
        query
      )}`,
      {
        isAdmin: true,
        useAdminAuth: true,
        method: REQUEST_METHODS.GET,
      }
    ),
    getLocalizationLanguageDetailByCode: (query) =>
    doFetch(`/openApi/getLocalizationLanguageDetailByCode${jsonToQuery(query)}`, {
      isAdmin: true,
      method: REQUEST_METHODS.GET,
    }),
};
