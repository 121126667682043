import { useCallback, useMemo, useRef } from "react";
import { Form } from "react-bootstrap";
import { AICCBuilderInitialConfig } from "../constants/AICCBuilderConfig";
import { AICCArithmeticSelector } from "./core/AICCArithmeticSelector";
import AICCFieldSelector from "./core/AICCFieldSelector";
import AICCRuleOperator from "./core/AICCRuleOperator";
import AICCValueSource from "./core/AICCValueSource";
import AICCValueEditor from "./value/AICCValueEditor";
import { getWidgetTypeBasedOnFieldType } from "../../../utils/coreUtils";

/**
 * {
 *  data: {
 *    id: "rule id",
 *    expressionId: "if expression is saved then it will be passed",
 *    group: "group id",
 *    selectedLeftArOp: "left side selected arithmetic operator",
 *    leftArOpValue: "left arithmetic value",
 *    selectedRightArOp: "left side selected arithmetic operator",
 *    rightArOpValue: "right arithmetic value",
 *    column: "column name",
 *    operator: "operator name",
 *    valueSource: "value | field"  // default value
 *    expectedValue: "expected value",
 *    midOperator: "AND | OR",
 *  },
 * index: 0, // this will be index of clause
 * }
 */

export default function AICCRule({
  data,
  index,
  language = AICCBuilderInitialConfig.language,
  useColumnGroup,
  columnsWithGroup = [],
  columnList = [],
  operatorList = AICCBuilderInitialConfig.operators,
  onAdd,
  onRemove,
  midOperatorVisible,
  addVisible,
  removeVisible,
  onChange,
  allowValueSource,
  allowArithmetic,
}) {
  const fieldSelectRef = useRef();
  const operatorSelectRef = useRef();
  const onChangeField = (key, value) => {
    if (onChange) {
      onChange({
        ...data,
        [key]: value,
      });
    }
  };

  const onChangeMultipleField = (obj) => {
    if (onChange) {
      onChange({
        ...data,
        ...obj,
      });
    }
  };

  const finalColumnList = useMemo(
    () =>
      useColumnGroup
        ? columnsWithGroup.find((e) => "" + e.value === "" + data?.group)?.subFields ?? []
        : columnList,
    [columnList, columnsWithGroup, data?.group, useColumnGroup]
  );
  const selectedField = useMemo(
    () => finalColumnList.find((e) => "" + e.value === "" + data?.column),
    [data?.column, finalColumnList]
  );

  const operatorObj = useMemo(
    () => operatorList.find((e) => e.value === data?.operator),
    [data?.operator, operatorList]
  );

  // console.log("selectedField", selectedField)

  const finalOperatorList = useMemo(
    () =>
      operatorList.filter((e) =>
        getWidgetTypeBasedOnFieldType(selectedField?.type) === "any"
          ? true
          : e.type === getWidgetTypeBasedOnFieldType(selectedField?.type) || e.value === data?.operator
      ),
    [data?.operator, operatorList, selectedField?.type]
  );

  const showValueSource = useMemo(
    () =>
      selectedField &&
      operatorObj &&
      !["_in"].some((e) => operatorObj.value.includes(e)) &&
      !operatorObj?.noInput &&
      allowValueSource,
    [allowValueSource, operatorObj, selectedField]
  );

  return (
    <div className="aicc-ruleBuilder__rule">
      <div className="aicc-ruleBuilder__rule--main-container align-items-center row g-0 gap-2">
        {useColumnGroup ? (
          <div className="col-auto aicc-ruleBuilder__rule--fields-group">
            <AICCFieldSelector
              columnList={columnsWithGroup}
              value={data?.group}
              placeholder={
                language?.fieldStreamPlaceholder ?? "Select Field Stream"
              }
              noOptionsLabel={
                language?.noFieldStreamOptions ?? "No field stream options"
              }
              onChange={(e) => {
                onChangeMultipleField({
                  group: e?.value,
                });

                setTimeout(() => {
                  if (fieldSelectRef?.current) {
                    fieldSelectRef.current.clearSelect();
                  }
                }, 10);
              }}
            />
          </div>
        ) : null}
        <div className="col-auto aicc-ruleBuilder__rule--fields">
          <AICCFieldSelector
            columnList={finalColumnList}
            value={data?.column}
            ref={fieldSelectRef}
            placeholder={language?.fieldPlaceholder ?? "Select Field"}
            noOptionsLabel={language?.noFieldOptions ?? "No field options"}
            onChange={(e) => {
              // onChangeField("column", e?.value);
              const oldField = finalColumnList.find(
                (item) => item.value === data?.column
              );

              onChangeMultipleField({
                column: e?.value,
              });

              setTimeout(() => {
                if (oldField?.type !== e?.type) {
                  if (operatorSelectRef?.current) {
                    operatorSelectRef.current.clearSelect();
                  }
                }
              }, 10);
            }}
          />
        </div>
        {allowArithmetic && (selectedField?.type === "number" || selectedField?.type === "decimal") && (
          <div className="col-auto">
            <AICCArithmeticSelector
              useColumnGroup={useColumnGroup}
              columnList={columnList}
              columnsWithGroup={columnsWithGroup}
              selectedOp={data?.selectedLeftArOp}
              leftArOpFieldGroup={data?.leftArOpFieldGroup}
              value={data?.leftArOpValue}
              selectValueSource={data?.leftArOpValueSource}
              enabled={data?.leftArithmetic}
              onChangeMultipleField={onChangeMultipleField}
              onCheckChanged={(checked) => {
                onChangeField("leftArithmetic", checked);
              }}
              onOpChange={(val) => {
                onChangeField("selectedLeftArOp", val);
              }}

              onChange={(val) => {
                onChangeField("leftArOpValue", val);
              }}
            />
          </div>
        )}
        <div className="col-auto aicc-ruleBuilder__rule--operator">
          {selectedField && (
            <AICCRuleOperator
              ref={operatorSelectRef}
              value={data?.operator}
              operatorList={finalOperatorList}
              placeholder={language?.operatorPlaceholder ?? "Select Operator"}
              noOptionsLabel={language?.noOperatorOptions ?? "No operators"}
              onChange={(e) => {
                // onChangeField("operator", e.value);

                onChangeMultipleField({
                  operator: e?.value,
                  expectedValue: e?.directValue ?? "",
                  noInput: e?.noInput,
                  value: "",
                  valueFieldGroup: "",
                  selectedRightArOp: "",
                  rightArOpValue: "",
                });

                /* if (operatorObj?.noInput) {
                    onChangeMultipleField({
                      operator: e?.value,
                      expectedValue: operatorObj?.directValue ?? "",
                    });
                  } else {
                    onChangeMultipleField({
                      operator: e?.value,
                    });
                  } */
              }}
            />
          )}
        </div>
        {showValueSource && (
          <div className="col-auto aicc-ruleBuilder__rule--value-source">
            <AICCValueSource
              value={data?.valueSource}
              onChange={(valueSource) => {
                onChangeField("valueSource", valueSource);
              }}
            />
          </div>
        )}
        <div className="col-auto aicc-ruleBuilder__rule--value">
          {selectedField && operatorObj && !operatorObj?.noInput && (
            <AICCValueEditor
              language={language}
              fieldList={finalColumnList}
              valueSource={data?.valueSource}
              selectedField={selectedField}
              allowArithmetic={allowArithmetic}
              selectedOperator={operatorObj}
              selectedRightArOp={data?.selectedRightArOp}
              rightArOpValue={data?.rightArOpValue}
              value={data?.expectedValue}
              valueFieldGroup={data?.valueFieldGroup}
              useColumnGroup={useColumnGroup}
              columnsWithGroup={columnsWithGroup}
              rightLeftArithmetic={data?.rightLeftArithmetic}
              rightRightArithmetic={data?.rightRightArithmetic}
              onCheckChanged={(checked, type) => {
                if (type) {
                  const key =
                    type === "min"
                      ? "rightLeftArithmetic"
                      : "rightRightArithmetic";
                  onChangeField(key, checked);
                } else {
                  onChangeField("rightLeftArithmetic", checked);
                }
              }}
              onOpChange={(val, type) => {
                if (type) {
                  const minMax = (data?.selectedRightArOp ?? "").split(",");
                  const changeIndex = type === "min" ? 0 : 1;
                  minMax[changeIndex] = val;

                  onChangeField("selectedRightArOp", minMax.join(","));
                } else {
                  onChangeField("selectedRightArOp", val);
                }
              }}
              onOpValueChange={(val, type) => {
                if (type) {
                  const minMax = (data?.rightArOpValue ?? "").split(",");
                  const changeIndex = type === "min" ? 0 : 1;
                  minMax[changeIndex] = val;

                  onChangeField("rightArOpValue", minMax.join(","));
                } else {
                  onChangeField("rightArOpValue", val);
                }
              }}
              onValueFieldGroup={(value) => {
                onChangeField("valueFieldGroup", value);
              }}
              onChange={(value, valType) => {
                if ((operatorObj?.value ?? "").includes("_between")) {
                  const minMax = (data?.expectedValue ?? "").split(",");
                  const changeIndex = valType === "min" ? 0 : 1;
                  minMax[changeIndex] = value;

                  onChangeField("expectedValue", minMax.join(","));
                } else {
                  onChangeField("expectedValue", value);
                }
              }}
            />
          )}
        </div>
        {removeVisible && (
          <div className="col-auto aicc-ruleBuilder__rule--removeIcon">
            <div>
              <span
                className="d-block fw-bold fs-6 rounded-2 cursor-pointer"
                onClick={onRemove}
              >
                <i class="fa-solid fa-circle-xmark"></i>
              </span>
            </div>
          </div>
        )}

        {/* <div className="col-auto d-flex aicc-ruleBuilder__rule--addIcon">
          <span
            className="d-block fw-bold ms-2 fs-6 rounded-2 cursor-pointer"
            onClick={onAdd}
          >
            <i class="fa-solid fa-circle-plus"></i>
          </span>
        </div> */}
      </div>
      {midOperatorVisible && (
        <div className="aicc-ruleBuilder__rule--combinator col-auto g-0 row my-1 justify-content-center position-relative align-items-center gap-3">
          <div className="aicc-ruleBuilder__rule--combinator--divider"></div>
          <div className="w-auto row g-0 gap-2">
            <Form.Check
              inline
              defaultChecked
              type={"radio"}
              label={language?.andCombinatorLabel ?? `AND`}
              id={`operation-type-and-${data.id}`}
              name={`operation-type-${data.id}`}
              checked={
                data?.midOperator === undefined || data?.midOperator === "and"
              }
              className="w-auto fw-bold"
              onChange={(e) => {
                onChangeField("midOperator", "and");
              }}
            />
            <Form.Check
              inline
              className="w-auto fw-bold"
              type={"radio"}
              label={language?.orCombinatorLabel ?? `OR`}
              id={`operation-type-or-${data.id}`}
              name={`operation-type-${data.id}`}
              checked={data?.midOperator === "or"}
              onChange={(e) => {
                onChangeField("midOperator", "or");
              }}
            />
          </div>
          <div className="aicc-ruleBuilder__rule--combinator--divider"></div>
        </div>
      )}
    </div>
  );
}
