import { useEffect } from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import CustomModal from "../CustomModal";

export default function RuleOrderModal({
  inputMax,
  inputMin,
  currOrder,
  onOkay,
  onClose,
  show,
}) {
  const [order, setOrder] = useState(currOrder?.index ?? -1);
  const [inputError, setInputError] = useState();

  useEffect(() => {
    setOrder(currOrder?.index);
    setInputError("");
  }, [currOrder?.index]);

  return (
    <CustomModal
      show={show}
      title={"Order Rule"}
      centered
      // body={<input type="number" min={inputMin} max={inputMax} />}
      body={
        <Form.Group>
          <Form.Control
            type="number"
            min={inputMin}
            max={inputMax}
            value={"" + order}
            autoFocus
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                if (
                  parseInt(order) < (inputMin ?? 0) ||
                  parseInt(order) >= (inputMax ?? 1)
                ) {
                  setInputError(
                    `Input must be greater then ${
                      (inputMin ?? 0) - 1
                    } and less then or equal to ${inputMax ?? 1}`
                  );
                  return;
                }
                e.preventDefault();
                if (onOkay) {
                  onOkay(currOrder, order);
                }
              }
            }}
            onChange={(e) => {
              if (
                parseInt(e.target.value) >= 0 &&
                parseInt(e.target.value) < (inputMax ?? 1)
              ) {
                if (inputError) {
                  setInputError("");
                }
              } else {
                if (!inputError) {
                  setInputError(
                    `Input must be greater then ${
                      (inputMin ?? 0) - 1
                    } and less then or equal to ${inputMax ?? 1}`
                  );
                }
              }
              setOrder(e.target.value);
            }}
          />
          {inputError && (
            <h6 className="text-danger mt-1 input-error-text">{inputError}</h6>
          )}
        </Form.Group>
      }
      onOk={() => {
        if (onOkay) {
          onOkay(currOrder, order);
        }
      }}
      onCancel={onClose}
      handleClose={onClose}
    />
  );
}
