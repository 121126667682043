const DangerButton = ({
  disabled,
  className = "",
  title,
  icon,
  type = "button",
  onClick,
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type={type}
      className={["btn btn-danger w-auto", className].join(" ")}
    >
      {icon && <i className={["me-2", icon].join(" ")}></i>}
      {title}
    </button>
  );
};

export default DangerButton;
