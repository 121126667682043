import languageTypes from "../actionTypes";
import CacheUtils from "../../../../utils/CacheUtils";
import openApi from "../../../../services/apis/openApi";
import axios from "axios";

export function localizationLanguageAction(languageCode) {
  return async function (dispatch) {
    try {
      dispatch({
        type: languageTypes.LANGUAGE,
        payload: {
          isLoading: true,
        },
      });

      const response = await openApi.getLocalizationLanguageDetailByCode({ languageCode });

      const cachedResponse = await CacheUtils.getCacheResponseTTL(
        "languageCache",
        response.data?.languageFileName
      );

      let res = undefined;

      if (cachedResponse) {
        res = {
          data: cachedResponse,
        };
      } else {
        res = await axios.get(response.data?.languageFileName);

        CacheUtils.cacheResponseTTL(
          "languageCache",
          response.data?.languageFileName,
          res.data,
          120
        );
      }

      dispatch({
        type: languageTypes.LANGUAGE,
        payload: {
          languageCodeData: res?.data,
        },
      });
      dispatch({
        type: languageTypes.LOADING,
        payload: {
          isLoading: false,
        },
      });
      return res?.data;
    } catch (error) {
      dispatch({
        type: languageTypes.LOADING,
        payload: {
          isLoading: false,
        },
      });
      console.log("err", error);
      throw error;
    }
  };
}
