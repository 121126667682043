import { t } from "i18next";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CenterLoading from "../../components/common/CenterLoading";
import DangerButton from "../../components/Form/Buttons/DangerButton";
import PrimaryButton from "../../components/Form/Buttons/PrimaryButton";
import RuleOrderModal from "../../components/Modals/selectedDataStream/RuleOrderModal";
import rulesApi from "../../services/apis/rulesApi";
import { reorder } from "../../utils/coreUtils";
import ToastUtils from "../../utils/ToastUtils";

const getItems = (count) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `item-${k}`,
    content: `item ${k}`,
  }));

export default function RulePriority() {
  const params = useParams();
  const navigate = useNavigate();

  const [list, setList] = useState(getItems(100));
  const [ruleOverviewList, setRuleOverviewList] = useState([]);
  const [currentItem, setCurrentItem] = useState();
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      ruleOverviewList,
      result.source.index,
      result.destination.index
    );

    setRuleOverviewList(items);
  }

  async function getAllRouteUserByDataStreamConfigId() {
    setIsLoading(true);
    try {
      const response = await rulesApi.getAllRulesByDSConfigVersionId({
        dsConfigVersionId: params?.versionId,
      });
      setRuleOverviewList(
        (response?.data ?? []).sort(function (a, b) {
          return a.priority - b.priority;
        })
      );
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }

  const savePriority = async () => {
    try {
      const response = await rulesApi.updatePriorityForRules(
        ruleOverviewList.map((e, index) => ({
          rulesId: e.rulesId,
          priority: index + 1,
        }))
      );

      if (response.data.status == 200) {
        toast.success(t(`statusCodes.${response.data.code}`));
      } else if (response.data.code) {
        ToastUtils.serverError(t(`statusCodes.${response.data.code}`));
      }
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllRouteUserByDataStreamConfigId();
  }, [params?.versionId]);

  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper p-0">
          <div className="main-panel">
            <div className="content-wrapper">
              <RuleOrderModal
                currOrder={currentItem}
                show={showOrderModal}
                inputMin={1}
                inputMax={ruleOverviewList.length + 1}
                onClose={() => {
                  setShowOrderModal(false);
                }}
                onOkay={(currOrder, order) => {
                  onDragEnd({
                    destination: {
                      index: parseInt(order) - 1,
                    },
                    source: {
                      index: currOrder.index - 1,
                    },
                  });
                  setCurrentItem(undefined);
                  setShowOrderModal(false);
                }}
              />

              {isLoading && <CenterLoading />}

              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(mainProvided, mainSnapshot) => (
                    <div
                      {...mainProvided.droppableProps}
                      ref={mainProvided.innerRef}
                      // style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {ruleOverviewList.map((item, index) => (
                        <Draggable
                          key={"" + item.rulesId}
                          draggableId={"" + item.rulesId}
                          index={index}
                        >
                          {(provided, snapshot) => {
                            return (
                              <div
                                className="p-4 d-flex"
                                id={"" + item.rulesId}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  backgroundColor: snapshot.isDragging
                                    ? "#004e5c"
                                    : "white",
                                  color: snapshot.isDragging
                                    ? "white"
                                    : "black",
                                  opacity: mainSnapshot.isDraggingOver
                                    ? snapshot.isDragging
                                      ? 1
                                      : 0.5
                                    : 1,
                                  boxShadow: "2px 2px 10px #d4d4d4",
                                  borderRadius: "8px",
                                  marginTop: "12px",
                                  fontWeight: "600",
                                }}
                              >
                                {item.name}

                                <button
                                  className="ms-3"
                                  onClick={() => {
                                    setCurrentItem({
                                      index: index + 1,
                                      item,
                                    });
                                    setShowOrderModal(true);
                                  }}
                                >
                                  Re-order
                                </button>
                              </div>
                            );
                          }}
                        </Draggable>
                      ))}
                      {mainProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <div
                className="bg-white d-flex justify-content-end mt-3 p-4 rounded-3 shadow sticky-top"
                style={{ bottom: "26px" }}
              >
                <PrimaryButton
                  title={t("CommonKeys.saveButtonTitle")}
                  icon="fa-regular fa-floppy-disk"
                  onClick={() => {
                    savePriority();
                  }}
                />
                <DangerButton
                  title={t("CommonKeys.cancelButtonTitle")}
                  className="ms-3"
                  icon={"fa-solid fa-xmark"}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
