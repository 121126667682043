import { menuReducerTypes } from "../reducers/menuReducer";

/* eslint-disable import/no-anonymous-default-export */
export default {
  menuActions(dataStreamConfigDetails) {
    return {
      type: menuReducerTypes.SELECT_DATA_STREAM,
      payload: dataStreamConfigDetails,
    };
  },
};
