import React from 'react'
import { Outlet, useParams, Navigate, useNavigate, useLocation } from 'react-router-dom'
import openApi from '../services/apis/openApi';
import { useEffect } from 'react';
import StorageManager from "../features/library/utils/storageManager";
import { getPathPrefix } from '../utils/coreUtils';
const CheckSelectedDataStream = () => {
  const navigate = useNavigate()
  const location = useLocation();

  const checkSelectedDataStreamIsExist = async () => {
    const { dataStreamConfigId } = JSON.parse(StorageManager.getSelectedDataStream());
    try {
      const { data } = await openApi.getDataStreamConfig();
      const isExistDataStream = data.some((element) => {
        return "" + element.dataStreamConfigId === "" + dataStreamConfigId
      });
      if (!isExistDataStream) {
        StorageManager.removeSelectedDataStream();
        navigate(getPathPrefix("dashboard"), {
          replace: true
        })
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    checkSelectedDataStreamIsExist();
  }, [location.pathname])
  return (
    <Outlet />
  )
}

export default CheckSelectedDataStream