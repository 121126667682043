import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import MainIndex from "./components/MainIndex";
import PasswordChanged from "./components/PasswordChanged";
import ForgotPassword from "./features/auth/pages/forgotPassword.page";
import Login from "./features/auth/pages/login.page";
import OtpPage from "./features/auth/pages/otp.page";
import ResetPassword from "./features/auth/pages/reset.page";
import HandleRouting from "./routing/HandleRouting";

function Page404() {
  return (
    <div
      className="row text-center align-items-center m-0 text-danger fw-bold"
      style={{
        height: "100vh",
      }}
    >
      <div>
        <h1 style={{ fontWeight: "bolder" }}>ERROR 404</h1>
        <p className="mb-0">Page Not Found</p>
        <p
          className="mb-0 text-center mx-auto"
          style={{
            maxWidth: "550px",
          }}
        >
          If domain name is not entered then please use valid url with company
          domain assigned to you.
        </p>
        {/* <p>Contact admin for support.</p> */}
      </div>
    </div>
  );
}

function App() {
  return (
    <>
      <Routes>
        <Route index element={<Page404 />} />
        <Route path=":companyDomain" element={<HandleRouting />}>
          <Route path=":languageCode" /* element={<HandleRouting />} */>
            <Route path="*" element={<MainIndex />} />
            <Route path="password-change" element={<PasswordChanged />} />
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="otp" element={<OtpPage />} />
            <Route path="new-user" element={<OtpPage />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
