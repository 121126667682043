import React from "react";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import RightPanelHeader from "../../components/rightPanelHeader.component";
import TextInput from "../../../../components/Form/Inputs/TextInput";
import PrimaryButton from "../../../../components/Form/Buttons/PrimaryButton";
import "./ChangePass.css";
import { getPathPrefix } from "../../../../utils/coreUtils";
import companyUserApi from "../../../../services/apis/companyUserApi";
import { toast } from "react-toastify";
import { ToastComponent } from "../../../../library/components/toast.component";
import { PasswordRegex } from "../../../../utils/RegexUtils";
import PasswordInput from "../../../../components/Form/Inputs/PasswordInput";
import ToastUtils from "../../../../utils/ToastUtils";

const ChangePassword = () => {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const initialFormValues = {
    newPassword: "",
    confirmPassword: "",
  };

  const handleSubmit = async (takeValue) => {
    console.log("takeValue", takeValue);
    await companyUserApi
      .changePassword({
        newPassword: takeValue.newPassword,
        otp: location.state?.Otp ?? "",
      })
      .then((res) => {
        // toast.success(t("changePassword.passwordChangedSuccess"));
        if (res.data.status != 200) {
          ToastUtils.serverError(t(`statusCodes.${res?.data?.code}`));
        } else {
          toast.success(t(`statusCodes.${res?.data?.code}`));
        }
        if (res.data.status === "200") {
          /*  navigate(getPathPrefix() + "dashboard", {
            replace: true,
          }); */
          navigate(getPathPrefix() + "login");
          localStorage.clear();
          window.location.reload();
        }
      });
  };

  const formValidationSchema = Yup.object({
    newPassword: Yup.string()
      .label(t("changePassword.newPassword.label"))
      .matches(PasswordRegex, t("changePassword.newPassword.matchesRegex"))
      .required(t("changePassword.newPassword.required")),
    confirmPassword: Yup.string()
    .label(t("changePassword.confirmPassword.label"))
      .oneOf(
        [Yup.ref("newPassword"), null],
        t("changePassword.confirmPassword.passwordNotMatch")
      )
      .matches(PasswordRegex, t("changePassword.confirmPassword.matchesRegex"))
      .required(t("changePassword.confirmPassword.required")),
  });

  return (
    <section className="page main-signin-wrapper container-layout align-items-center main-password-change">
      <div className="row bg-white card-view">
        <div className="col-lg-12">
          <div className="form-container changPass">
            <RightPanelHeader title={t("changePassword.title")} />
            <Formik
              initialValues={initialFormValues}
              validateOnMount
              className="mt-2"
              validationSchema={formValidationSchema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isValid,
                isSubmitting,
                dirty,
                ...props
              }) => (
                <Form onSubmit={handleSubmit} className="filed-confirmPassword">
                  <Field
                    component={PasswordInput}
                    label={t("changePassword.newPassword.label")}
                    id="newPassword"
                    name="newPassword"
                    placeholder={t("changePassword.newPassword.placeholder")}
                    type="password"
                    autoComplete="new-password"
                  />
                  <Field
                    component={PasswordInput}
                    label={t("changePassword.confirmPassword.label")}
                    placeholder={t(
                      "changePassword.confirmPassword.placeholder"
                    )}
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    autoComplete="new-password"
                  />

                  <div className="row justify-content-end mb-3">
                    <PrimaryButton
                      type="submit"
                      title={t("CommonKeys.submitButtonTitle")}
                      className="btn btn-green w-auto w-75"
                    />
                    <PrimaryButton
                      title={t("CommonKeys.cancelButtonTitle")}
                      className="btn btn-green w-auto w-75"
                      onClick={() => {
                        navigate(getPathPrefix("dashboard"), {
                          replace: true,
                        });
                      }}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {/* <ToastComponent /> */}
    </section>
  );
};

export default ChangePassword;
