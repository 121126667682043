import { jsonToQuery } from "../../utils/ServicesUtils";
import { doFetch, REQUEST_CONTENT_TYPE, REQUEST_METHODS } from "../fetcher";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  saveOrUpdateCustomFields: (data, query) =>
    doFetch(`/customFields/saveOrUpdateCustomFields${jsonToQuery(query)}`, {
      method: REQUEST_METHODS.POST,
      body: data,
    }),
  saveOrUpdateCustomFieldsList: (data, query) =>
    doFetch(`/customFields/saveOrUpdateCustomFieldsList${jsonToQuery(query)}`, {
      method: REQUEST_METHODS.POST,
      body: data,
    }),
  getCustomFieldsByDataStreamConfigId: (query) =>
    doFetch(
      `/customFields/getCustomFieldsByDataStreamConfigId${jsonToQuery(query)}`
    ),
  getCustomFieldsByDataStreamConfigIdAndSchemaId: (query) =>
    doFetch(
      `/customFields/getCustomFieldsByDataStreamConfigIdAndSchemaId${jsonToQuery(
        query
      )}`
    ),
  getGroupNameByDataStreamConfigIdAndSchemaId: (query) =>
    doFetch(
      `/customFields/getGroupNameByDataStreamConfigIdAndSchemaId${jsonToQuery(
        query
      )}`
    ),
  getGroupNameByDataStreamConfigIdAndDataStreamConfigApiId: (query) =>
    doFetch(
      `/customFields/getGroupNameByDataStreamConfigIdAndDataStreamConfigApiId${jsonToQuery(
        query
      )}`
    ),
  deleteCustomFieldsByIdWithCheckingRules: (query) =>
    doFetch(
      `/customFields/deleteCustomFieldsByIdWithCheckingRules${jsonToQuery(
        query
      )}`,
      {
        method: REQUEST_METHODS.DELETE,
      }
    ),
};
