export const isEmpty = (value) => {
    return value === undefined || value === null || value === '';
}

export const isGreaterThanZero = (num) => {
    return num > 0;
}

export const passwordValidate = (password) => {
    const re = {
        'capital': /[A-Z]/,
        'digit': /[0-9]/,
        'except': /[aeiou]/,
        'full': /^[@#][A-Za-z0-9]{7,13}$/
    };
    return re.capital .test(password) &&
        re.digit   .test(password) &&
        !re.except  .test(password) &&
        re.full    .test(password);
}
