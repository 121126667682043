import PrimaryButton from "../../../../components/Form/Buttons/PrimaryButton";
import { AICCBuilderInitialConfig } from "../../constants/AICCBuilderConfig";

export default function AICCRuleGroupHeader({
  onAdd,
  language = AICCBuilderInitialConfig.language,
}) {
  return (
    <div className="row justify-content-end aicc-ruleBuilder__rule-header">
      <div className="col-auto">
        <PrimaryButton
          title={language?.addRuleButtonLabel ?? "Rule"}
          icon="fa-solid fa-plus"
          onClick={onAdd}
        />
      </div>
      {/*  <div className="col-auto">
        <PrimaryButton title={language?.addGroupButtonLabel ?? "Group"} icon="fa-solid fa-plus" className="" />
      </div> */}
    </div>
  );
}
