export default function AiccBreadCrumb({ title, items = [], homeLink }) {
  return (
    <div className="page-breadcrumb d-none d-md-flex align-items-center mb-4">
      {title && <div className="breadcrumb-title pe-3">{title}</div>}
      <div className="ps-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mb-0 p-0">
            <li className="breadcrumb-item">
              <a href={homeLink}>
                <i className="fa-solid fa-house" />
              </a>
            </li>
            {items.map((item,i) => (
              <li className="breadcrumb-item active" aria-current="page" key={i}>
                {item.redirect ? (
                  <li className="breadcrumb-item">
                    <a href="#">{item.name}</a>
                  </li>
                ) : (
                  item.name
                )}
              </li>
            ))}
          </ol>
        </nav>
      </div>
    </div>
  );
}
