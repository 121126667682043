import React from "react";
import { NavLink } from "react-router-dom";
import LeftPanel from "../features/auth/components/leftPanel.component";
import { getPathPrefix } from "../utils/coreUtils";

function PasswordChanged() {
  return (
    <>
      <section className="main-signin-wrapper container-layout">
        <div className="row signpages bg-app-primary">
          <div className="col-md-12">
            <div className="card">
              <div className="row">
                <LeftPanel />
                <div className="col-lg-6 right-panel">
                  <div className="form-container">
                    <div className="pass-check">
                      <i className="fa-sharp fa-solid fa-check"></i>
                    </div>
                    <div className="heading">
                      <h4>Password Changed!</h4>
                      <h6>Your password has been changed successfully.</h6>
                    </div>
                    <form>
                      <div className="form-group text-center">
                        <button
                          type="submit"
                          className="btn btn-fill text-uppercase"
                        >
                          Continue
                        </button>
                      </div>
                      <div className="form-group text-center">
                        <NavLink to={getPathPrefix("login")} className="">
                          Back to <b>Log-in</b>
                        </NavLink>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PasswordChanged;
