import { createRef } from "react";
import { useEffect, useMemo, useRef } from "react";
import { Form } from "react-bootstrap";
import ReactSelect from "react-select";
import { AICCDateTimeInputsValue } from "./inputs/AICCDateTimeInputsValue";
import { AICCFieldInputValue } from "./inputs/AICCFieldInputValue";
import { AICCNumberInputs } from "./inputs/AICCNumberInputs";

export default function AICCValueEditor({
  selectedField,
  selectedOperator,
  valueFieldGroup,
  value,
  onChange,
  onValueFieldGroup,
  language,
  valueSource,
  fieldList,
  useColumnGroup,
  columnsWithGroup = [],
  selectedRightArOp,
  rightArOpValue,
  onOpChange,
  onOpValueChange,
  rightLeftArithmetic,
  rightRightArithmetic,
  onCheckChanged,
  allowArithmetic,
}) {
  const finalColumnList = useMemo(
    () =>
      useColumnGroup
        ? columnsWithGroup.find((e) => e.value == valueFieldGroup)?.subFields ??
        []
        : fieldList,
    [fieldList, columnsWithGroup, valueFieldGroup, useColumnGroup]
  );

  if (valueSource === "field") {
    return (
      <AICCFieldInputValue
        {...{
          language,
          value,
          onChange,
          selectedOperator,
          columnsWithGroup,
          fieldList,
          finalColumnList,
          onValueFieldGroup,
          useColumnGroup,
          valueFieldGroup,
          selectedRightArOp,
          rightArOpValue,
          onOpChange,
          onOpValueChange,
          rightLeftArithmetic,
          rightRightArithmetic,
          onCheckChanged,
          allowArithmetic,
        }}
      />
    );
  }

  if ((selectedOperator?.value ?? "").includes("_in")) {
    // if options is available to select then show dropdown
    if (selectedField?.fieldOptions) {
      const allFieldOptions = selectedField?.fieldOptions ?? [];

      const splittedValues = (value ?? "").split(",").map((e) => e.trim());
      const selectedOptions = allFieldOptions.filter((e) =>
        splittedValues.includes(e.value)
      );

      return (
        <div className="form-group mb-0">
          <ReactSelect
            classNamePrefix={"select"}
            value={selectedOptions}
            options={selectedField?.fieldOptions}
            isMulti
            menuPosition={"fixed"}
            placeholder={language?.valueAnySelectPlaceholder}
            noOptionsMessage={() => language?.valueAnySelectNoOptions}
            onChange={(selectedOption) => {
              if (onChange) {
                onChange((selectedOption ?? []).map((e) => e.value).join(","));
              }
            }}
          />
        </div>
      );
    }

    // if there are no options available to select then show input
    return (
      <Form.Group className="form-group mb-0">
        <Form.Control
          type={"text"}
          placeholder={
            language?.commaSeparatedInputPlaceholder ??
            "Enter comma separated values"
          }
          value={value}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value);
            }
          }}
        />
        {/* {selectedOperator?.type === "number" &&
          value &&
          !/^(([0-9]+)(,(?=[0-9]))?)+$/gm.test(value) && (
            <h6 className="text-danger mt-1 input-error-text position-absolute">
              {language?.commaSeparatedInputNumberError ??
                "Please enter valid comma separated numbers."}
            </h6>
          )} */}
      </Form.Group>
    );
  }

  if (selectedOperator?.type === "boolean") {
    return (
      <div className="switch mt-1">
        <input
          type="checkbox"
          checked={typeof value === "string" ? value.toLowerCase() === "true" : value}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.checked);
            }
          }}
        />
      </div>
    );
  }

  if (selectedOperator?.type === "datetime") {
    // if selected operator is between and date time is value type then show date time picker.
    return (
      <AICCDateTimeInputsValue
        language={language}
        value={value}
        onChange={onChange}
        selectedOperator={selectedOperator}
      />
    );
  }

  if (selectedOperator?.type === "number" || selectedOperator?.type === "decimal") {
    // if operator operator is between and type is number then show min max input in same line
    return (
      <AICCNumberInputs
        {...{
          language,
          value,
          onChange,
          selectedOperator,
          selectedRightArOp,
          rightArOpValue,
          onOpChange,
          onOpValueChange,
          allowArithmetic,
        }}
      />
    );
  }

  // if nothing is matching then show text input
  return (
    <Form.Group className="form-group mb-0">
      <Form.Control
        type={"text"}
        placeholder={language?.stringInputPlaceholder ?? "Enter string"}
        value={value}
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.value);
          }
        }}
      />
    </Form.Group>
  );
}
