import ReactSelect from "react-select";

const SelectInput = ({
  field: { value, onChange, name, ...fields }, // { name, value, onChange, onBlur }
  form: { touched, errors, isSubmitting, setFieldValue, ...forms }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  required,
  defaultValue,
  options = [],
  isSearchable,
  initValidation,
  disabled,
  ...props
}) => (
  <div className="form-group">
    <label className="mb-2 form-label">
      {label}
      {required && (
        <sup>
          <i
            className="fa-solid fa-star-of-life"
            style={{
              color: "red",
              fontSize: "5px",
              marginBottom: "5px",
            }}
          ></i>
        </sup>
      )}
    </label>
    <ReactSelect
      classNamePrefix="select"
      isSearchable={isSearchable}
      options={options}
      {...fields}
      {...{ ...props, required }}
      disabled={isSubmitting}
      value={options.find((e) => "" + e.value === value)}
      isOptionSelected={(option) => "" + option.value === value}
      onChange={(e) => {
        setFieldValue(name, e.value);
      }}
      isDisabled={disabled}
    />
    {initValidation
      ? errors[fields.name]
      : touched[fields.name] &&
        errors[fields.name] && (
          <h6 className="text-danger mt-1 input-error-text">
            {errors[fields.name]}
          </h6>
        )}
  </div>
);

export default SelectInput;
