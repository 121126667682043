import React from "react";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <span
    className="cursor-pointer d-block"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </span>
));

export default CustomToggle;
