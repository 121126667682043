import { useTranslation } from "react-i18next";
import CustomModal from "./CustomModal";

const ConfirmActivateModal = ({ show, onClose, onOk, messageTitles }) => {
  const [t] = useTranslation()

  const viewToRender = () => {
    return (
      <div>
        <span className="fw-bold text-danger">{messageTitles}</span>
      </div>
    );
  };

  return (
    <CustomModal
      show={show}
      handleClose={onClose}
      title={t("alterRulePriority.breadCrumb.title")}
      body={viewToRender()}
      centered
      okTitle={t("CommonKeys.yes")}
      onOk={onOk}
      cancelTitle={t("CommonKeys.no")}
      onCancel={onClose}
    />
  );
};

export default ConfirmActivateModal;
