import React from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const CusPhoneInput = ({
  label,
  placeholder,
  value,
  onChange,
  onBlur,
  required,
  error,
  touched,
  disabled,
  initValidation,
}) => {
  return (
    <div className="form-group">
      {label && (
        <label className="mb-2 form-label">
          {label}
          {required && (
            <sup>
              <i
                className="fa-solid fa-star-of-life"
                style={{
                  color: "red",
                  fontSize: "5px",
                  marginBottom: "5px",
                }}
              ></i>
            </sup>
          )}
        </label>
      )}
      <PhoneInput
        country="SE"
        international
        countryCallingCodeEditable={false}
        defaultCountry="SE"
        inputClass="w-100"
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          if (onChange) {
            onChange(e ?? "");
          }
        }}
        disabled={disabled}
        onBlur={onBlur}
        required={required}
      />
      {(initValidation ? error : touched && error) && (
        <h6 className="text-danger mt-1 input-error-text">{error}</h6>
      )}
    </div>
  );
};

export default CusPhoneInput;
