/* eslint-disable no-unused-vars */
import axios from "axios";
import StorageManager from "./storageManager";

const appJson =  "application/json";

var sethmac;
var paramsData;
const setHeaders = (isAuthRequired) => {
  return new Promise((resolve, reject) => {
    if (isAuthRequired) {
      const user = StorageManager.getObject("user");
      const authToken = localStorage.getItem("authToken");
        resolve({
          "Content-Type": appJson,
          Authorization: `Bearer ${authToken}`
        });
    } else {
      resolve({
        "Content-Type": appJson,
      });
    }
  });
};

const logError = (error) => {
  if (error["response"]) {
  } else {
  }
};

class NetworkManger {
  static get(url, isAuthRequired = true, params = {}, hmac) {
    let config = {};
    sethmac = hmac;
    paramsData = params;

    return setHeaders(isAuthRequired)
      .then((headers) => {
        config["headers"] = headers;
        config["params"] = new URLSearchParams(params);
        return axios.get(url, config);
      })
      .catch((err) => {
        if (
          err.response &&
          (err.response.status === 401)
        ) {
          window.location.assign(window.location.origin + "/");
        }
        
        logError(err);
        throw err;
      });
  }
  static post(
    url,
    data,
    isAuthRequired = true,
    params = {},
    hmac = true
  ) {
    let config = {};
    sethmac = hmac;
    paramsData = data;
    return setHeaders(isAuthRequired)
      .then((headers) => {
        config["headers"] = headers;
        config["params"] = params;
        return axios.post(url, data, config);
      })
      .catch((err) => {
        if (
          !url.includes("authenticate") ||
          (data && data["grantType"] === "refresh_token")
        ) {
          if (
            err.response &&
            (err.response.status === 401 || err.response.status === 403)
          ) {
            // window.location.assign(window.location.origin + "/");
          }
        }
        logError(err);
        throw err?.response;
      });
  }

  static postMultipart(url, data, isAuthRequired = true, params = {}) {
    let config = {};
    return setHeaders(isAuthRequired)
      .then((headers) => {
        config["headers"] = isAuthRequired ? {
          "Content-Type": "multipart/form-data; boundary=MultipartBoundary",
          Authorization:
            StorageManager.getObject("user")?.accessToken,
        } : {
          "Content-Type": "multipart/form-data; boundary=MultipartBoundary",
        };
        return axios.post(url, data, config);
      })
      .catch((err) => {
        if (
          err.response &&
          (err.response.status === 401)
        ) {
          console.log(err.response);
          // window.location.assign(window.location.origin + "/");
        }
        logError(err);
        throw err;
      });
  }

  static putMultipart(url, data, isAuthRequired = true, params = {}) {
    let config = {};
    return setHeaders(isAuthRequired)
      .then((headers) => {
        config["headers"] = isAuthRequired ? {
          "Content-Type": "multipart/form-data; boundary=MultipartBoundary",
          Authorization:
            StorageManager.getObject("user")?.accessToken,
        } : {
          "Content-Type": "multipart/form-data; boundary=MultipartBoundary",
        };
        return axios.put(url, data, config);
      })
      .catch((err) => {
        if (
          err.response &&
          (err.response.status === 401 || err.response.status === 403)
        ) {
          console.log(err.response);
          // window.location.assign(window.location.origin + "/");
        }
        logError(err);
        throw err;
      });
  }

  static put(url, data, isAuthRequired = true, params = {}) {
    let config = {};
    return setHeaders(isAuthRequired)
      .then((headers) => {
        config["headers"] = headers;
        config["params"] = params;
        return axios.put(url, data, config);
      })
      .catch((err) => {
        if (
          err.response &&
          (err.response.status === 401)
        ) {
          window.location.assign(window.location.origin + "/");
        }
        logError(err);
        throw err;
      });
  }
  static delete(url, isAuthRequired = true, params = {}) {
    let config = {};
    return setHeaders(isAuthRequired)
      .then((headers) => {
        config["headers"] = headers;
        config["params"] = params;
        return axios.delete(url, config);
      })
      .catch((err) => {
        if (
          err.response &&
          (err.response.status === 401)
        ) {
          window.location.assign(window.location.origin + "/");
        }
        logError(err);
        throw err;
      });
  }
}

export default NetworkManger;
