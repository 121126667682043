import { t } from "i18next";
import { useCallback } from "react";
import { useImperativeHandle } from "react";
import { forwardRef } from "react";
import { useState, useMemo, useEffect } from "react";
import CenterLoading from "../../components/common/CenterLoading";
import PrimaryButton from "../../components/Form/Buttons/PrimaryButton";
import CustomModal from "../../components/Modals/CustomModal";
import AICCRuleBuilder from "../../lib/aiccRuleBuilder/components/AICCRuleBuilder";
import AICCRuleBuilderUtils from "../../lib/aiccRuleBuilder/utils/AICCRuleBuilderUtils";
import customFields from "../../services/apis/customFields";
import openApi from "../../services/apis/openApi";
import rulesApi from "../../services/apis/rulesApi";
import RuleBuilderConvert from "../../utils/converter/RuleBuilderConvert";
import StorageManager from "../library/utils/storageManager";
import "bootstrap/dist/css/bootstrap.min.css";
import { useQueryContext } from "./context/RuleEndingProvider";

const CusRuleBuilder = forwardRef(
  ({ onQueryChange, overviewId, viewOnly }, ref) => {
    const { setQuery: setContextQuery } =
      useQueryContext();
    const [groupData, setGroupData] = useState([]);
    const [ruleQueryString, setRuleQueryString] = useState("");
    const [showQuery, setShowQuery] = useState(false);
    const [confirmQueryReset, setConfirmQueryReset] = useState(false);
    const [query, setQuery] = useState([AICCRuleBuilderUtils.defaultQuery()]);
    const [configList, setConfigList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    useImperativeHandle(
      ref,
      () => ({
        getQuery: () => {
          return query;
        },
      }),
      [query]
    );

    const selectedDataStream = useMemo(
      () => StorageManager.getSelectedDataStream(),
      []
    );

    const streamObj = useMemo(
      () => (selectedDataStream ? JSON.parse(selectedDataStream) : undefined),
      [selectedDataStream]
    );

    const getRuleById = async () => {
      // setIsLoading(true);

      if (!overviewId) {
        setIsLoading(false);
        return;
      }

      try {
        const response = await rulesApi.getRulesById({
          rulesId: overviewId,
        });

        if (
          response.data?.whereClauses &&
          response.data?.whereClauses.length > 0
        ) {
          setRuleQueryString(response.data?.queryString);

          const respQuery = RuleBuilderConvert.aiccRBWhereClauseToQuery(
            response.data.whereClauses
          );

          setQuery(respQuery);
          setContextQuery(respQuery);
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };

    const getDataStreamConfig = async () => {
      try {
        const { data } = await openApi.getDataStreamConfig();

        if (typeof data !== "string") {
          setConfigList(data ?? []);

          getRuleById();
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };

    const getFieldConfigJson = async (dataStreamConfigId, schemaId) => {
      if (!dataStreamConfigId /* || !schemaId */) {
        return;
      }
      setIsLoading(true);
      try {
        const response = await customFields.getCustomFieldsByDataStreamConfigId(
          {
            dataStreamConfigId,
            schemaId,
          }
        );

        setGroupData(response.data);

        getDataStreamConfig();
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    useEffect(() => {
      if (streamObj?.dataStreamConfigId) {
        getFieldConfigJson(streamObj?.dataStreamConfigId);
      }
    }, [streamObj?.dataStreamConfigId]);

    const fieldsWithGroup = useMemo(() => {
      /* console.log(
        "api details",
        configList.flatMap((e) => e.apiDetails ?? [])
      ); */
      return RuleBuilderConvert.aiccRBFieldConfigToGroupedFields(groupData, [
        ...configList.flatMap((e) => e.aiccDataStreamJsons),
        ...configList
          .flatMap((e) => e.apiDetails ?? [])
          .map((e) => ({
            ...e,
            jsonId: e.dataStreamConfigApiId,
            jsonFileSchemaName: e.apiName,
          })),
      ]);
    }, [configList, groupData]);

    const queryChangeCallback = useCallback(
      (myQuery) => {
        setQuery(myQuery);
        setContextQuery(myQuery);

        if (onQueryChange) {
          onQueryChange(myQuery);
        }
      },
      [onQueryChange, setContextQuery]
    );

    return (
      <div>
        <div>
          <div className="row p-0 justify-content-end mb-3">
            {ruleQueryString && (
              <div className="col-auto">
                <PrimaryButton
                  title={t("createRuleEngine.ruleCreationTab.viewQueryButton")}
                  icon="fa-regular fa-eye"
                  onClick={() => {
                    setShowQuery(true);
                  }}
                />
              </div>
            )}
            <div className="col-auto">
              <PrimaryButton
                title={t("CommonKeys.reset")}
                icon="fa-solid fa-arrows-rotate"
                onClick={() => {
                  setConfirmQueryReset(true);
                }}
                disabled={
                  viewOnly ||
                  (query ?? []).filter((e) => !e.isDeleted).length <= 0
                }
              />
            </div>
          </div>
        </div>
        <CustomModal
          size={"xl"}
          show={showQuery}
          handleClose={() => setShowQuery(false)}
          title={t("createRuleEngine.breadCrumb.title")}
          body={ruleQueryString}
          centered
          okTitle={t("CommonKeys.okButtonTitle")}
          onOk={() => setShowQuery(false)}
        />

        <CustomModal
          show={confirmQueryReset}
          handleClose={() => setConfirmQueryReset(false)}
          title={t("createRuleEngine.breadCrumb.title")}
          body={
            <span className="text-danger fw-bold">
              {t("createRuleEngine.resetRuleBuilder")}
            </span>
          }
          centered
          okTitle={t("CommonKeys.yes")}
          onOk={() => {
            const temp = Array.from(query);

            const finalQuery = temp
              .filter((e) => e.expressionId)
              .map((e) => ({ ...e, isDeleted: true }));

            setQuery(finalQuery);
            setContextQuery(finalQuery);
            setConfirmQueryReset(false);
          }}
          cancelTitle={t("CommonKeys.no")}
          onCancel={() => {
            setConfirmQueryReset(false);
          }}
        />

        {isLoading ? (
          <CenterLoading />
        ) : (
          <div className={`${viewOnly ? "inactive-div" : ""}`}>
            <AICCRuleBuilder
              query={query}
              onChangeQuery={queryChangeCallback}
              useFieldGroup
              allowValueSource
              allowArithmetic
              fieldsWithGroup={fieldsWithGroup}
              language={t("aiccRuleBuilder", {
                returnObjects: true,
              })}
            />
          </div>
        )}
      </div>
    );
  }
);

export default CusRuleBuilder;
