import { combineReducers } from "redux";
import authReducer from "../../../features/auth/redux/reducers/auth.reducer";
import { companyUserReducer } from "../../../features/company_users/redux/reducers/company_user_reducer";
import { langReducer } from "../../../features/language/redux/reducers/lang_reducer";
import menuReducer from "./menuReducer";

export default combineReducers({
  authReducer: authReducer,
  companyUserReducer: companyUserReducer,
  langReducer: langReducer,
  menuReducer: menuReducer,
});
