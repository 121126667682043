const types = {
    LOADING: "loading",
    IS_DYNAMIC_MESSAGE: "dynamic_message",
    LOGIN: "authenticate",
    CHANGE_PASSWORD: 'companyUser/changePassword',
    CHANGE_PASSWORD_OTP: 'companyUser/generateOtpForChangePassword',
    FORGET_PASSWORD: 'companyNewUser/generateOtpForForgotPassword',
    SET_PASSWORD_FOR_FORGOT_PASSWORD: 'companyNewUser/setPasswordForForgotPassword',
    OTP_VERIFICATION_FOR_FORGOT_PASSWORD: 'companyNewUser/otpVerificationForForgotPassword',
    OTP_VERIFICATION_FOR_CHANGE_PASSWORD: 'companyNewUser/otpVerificationForChangePassword',
    NEW_USER_OTP: 'companyNewUser/generateOtpForNewUser',
    SET_PASSWORD_FOR_NEW_USER: 'companyNewUser/setPasswordForNewUser',

}

export default types;