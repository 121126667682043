import { BASE_URL } from "../../../../constants";
import NetworkManager from "../../../library/utils/networkManager";
import { AUTH_ENDPOINT } from "../endPoints/index";
import types from "../actionTypes";

const baseUrl = BASE_URL;
export function loginAction(body) {
  return async function (dispatch) {
    try {
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: true,
        },
      });
      const response = await NetworkManager.post(
        baseUrl + AUTH_ENDPOINT.LOGIN,
        body,
        false
      );
      dispatch({
        type: types.LOGIN,
        payload: {
          loginData: response?.data,
          companyUserIdData: response?.data.companyUserId,
        },
      });
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: false,
        },
      });
      return response?.data;
    } catch (err) {
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: false,
        },
      });
      throw err;
    }
  };
}

export function changePasswordAction(newPassword, otp) {
  return async function (dispatch) {
    try {
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: true,
        },
      });
      const response = await NetworkManager.post(
        baseUrl + AUTH_ENDPOINT.CHANGE_PASSWORD,
        {
          newPassword,
          otp,
        },
        true
      );
      dispatch({
        type: types.CHANGE_PASSWORD,
        payload: {
          changePasswordData: response?.data,
        },
      });
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: false,
        },
      });
      return response?.data;
    } catch (err) {
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: false,
        },
      });
      throw err;
    }
  };
}

export function changePasswordOtpAction(oldPassword) {
  return async function (dispatch) {
    try {
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: true,
        },
      });
      const response = await NetworkManager.post(
        baseUrl + AUTH_ENDPOINT.CHANGE_PASSWORD_OTP,
        {
          oldPassword,
        },
        true
      );
      dispatch({
        type: types.CHANGE_PASSWORD_OTP,
        payload: {
          changePasswordOtpData: response?.data,
        },
      });
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: false,
        },
      });
      return response?.data;
    } catch (err) {
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: false,
        },
      });
      throw err;
    }
  };
}
export function changeForgetPasswordAction(emailId, userCode) {
  return async function (dispatch) {
    try {
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: true,
        },
      });
      const companyDetails = JSON.parse(
        localStorage.getItem("domainInfo") ?? "{}"
      );

      const response = await NetworkManager.post(
        baseUrl + AUTH_ENDPOINT.FORGET_PASSWORD,
        {
          emailId,
          userCode,
          companyId: companyDetails?.companyId,
        },
        false
      );
      dispatch({
        type: types.FORGET_PASSWORD,
        payload: {
          changeForgetPasswordData: response?.data,
          changeForgetPasswordMailData: emailId,
        },
      });
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: false,
        },
      });
      return response?.data;
    } catch (err) {
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: false,
        },
      });
      console.log(err);
      throw err;
    }
  };
}

export function setPasswordForForgotPasswordAction(emailId, newPassword, otp) {
  return async function (dispatch) {
    try {
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: true,
        },
      });

      const companyDetails = JSON.parse(
        localStorage.getItem("domainInfo") ?? "{}"
      );
      const response = await NetworkManager.post(
        baseUrl + AUTH_ENDPOINT.SET_PASSWORD_FOR_FORGOT_PASSWORD,
        {
          emailId,
          newPassword,
          otp,
          companyId: companyDetails?.companyId,
        },
        false
      );
      dispatch({
        type: types.SET_PASSWORD_FOR_FORGOT_PASSWORD,
        payload: {
          setPasswordForForgotPasswordData: response?.data,
        },
      });
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: false,
        },
      });
      return response?.data;
    } catch (err) {
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: false,
        },
      });
      throw err;
    }
  };
}

export function otpVerificationForForgotPasswordAction(emailId, otp) {
  return async function (dispatch) {
    try {
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: true,
        },
      });
      const companyDetails = JSON.parse(
        localStorage.getItem("domainInfo") ?? "{}"
      );
      const response = await NetworkManager.post(
        baseUrl + AUTH_ENDPOINT.OTP_VERIFICATION_FOR_FORGOT_PASSWORD,
        {
          emailId,
          otp,
          companyId: companyDetails?.companyId,
        },
        false
      );
      dispatch({
        type: types.OTP_VERIFICATION_FOR_FORGOT_PASSWORD,
        payload: {
          otpVerificationForForgotPasswordData: response?.data,
        },
      });
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: false,
        },
      });
      return response?.data;
    } catch (err) {
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: false,
        },
      });
      throw err;
    }
  };
}

export function otpVerificationForChangePasswordAction(otp) {
  return async function (dispatch) {
    try {
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: true,
        },
      });
      /* const response = await NetworkManager.post(
        baseUrl + AUTH_ENDPOINT.OTP_VERIFICATION_FOR_CHANGE_PASSWORD,
        null,
        true,
        {
          otp,
        }
      ); */
      const response = await NetworkManager.post(
        baseUrl + AUTH_ENDPOINT.OTP_VERIFICATION_FOR_CHANGE_PASSWORD,
        {
          otp,
        },
        true
      );
      dispatch({
        type: types.OTP_VERIFICATION_FOR_CHANGE_PASSWORD,
        payload: {
          otpVerificationForChangePasswordData: response?.data,
        },
      });
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: false,
        },
      });
      return response?.data;
    } catch (err) {
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: false,
        },
      });
      throw err;
    }
  };
}
export function newUserOtpAction(encryptedKey) {
  return async function (dispatch) {
    try {
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: true,
        },
      });

      const companyDetails = JSON.parse(
        localStorage.getItem("domainInfo") ?? "{}"
      );
      const response = await NetworkManager.post(
        baseUrl + AUTH_ENDPOINT.NEW_USER_OTP,
        {
          encryptedKey,
          companyId: companyDetails?.companyId,
        },
        false
      );
      dispatch({
        type: types.NEW_USER_OTP,
        payload: {
          newUserOtpData: response?.data,
        },
      });
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: false,
        },
      });
      return response?.data;
    } catch (err) {
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: false,
        },
      });
      throw err;
    }
  };
}

export function setPasswordForNewUserAction(encryptedKey, newPassword, otp) {
  return async function (dispatch) {
    const companyDetails = JSON.parse(
      localStorage.getItem("domainInfo") ?? "{}"
    );
    try {
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: true,
        },
      });
      const response = await NetworkManager.post(
        baseUrl + AUTH_ENDPOINT.SET_PASSWORD_FOR_NEW_USER,
        {
          encryptedKey,
          newPassword,
          otp,
          companyId: companyDetails?.companyId,
        },
        false
      );
      dispatch({
        type: types.SET_PASSWORD_FOR_NEW_USER,
        payload: {
          setPasswordForNewUserData: response?.data,
        },
      });
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: false,
        },
      });
      return response?.data;
    } catch (err) {
      dispatch({
        type: types.LOADING,
        payload: {
          isLoading: false,
        },
      });
      throw err;
    }
  };
}
