import moment from "moment";

export default class CacheUtils {
  // seconds
  static cacheDuration = 30;

  static cacheResponseTTL(cacheName, url, response, ttl) {
    const cacheJson = {
      ttl: moment()
        .add(ttl ?? this.cacheDuration, "seconds")
        .toISOString(),
      response,
    };

    const data = new Response(JSON.stringify(cacheJson));

    if ("caches" in window) {
      // Opening given cache and putting our data into it
      caches.open(cacheName).then((cache) => {
        cache.put(url, data);
      });
    }
  }

  static async getCacheResponseTTL(cacheName, url) {
    // Opening that particular cache
    const cacheStorage = await caches.open(cacheName);

    // Fetching that particular cache data
    const cachedResponse = await cacheStorage.match(url);
    if (!cachedResponse) return undefined;
    const data = await cachedResponse.json();

    if (moment().isAfter(moment(data.ttl))) {
      caches.delete(cacheName);
      return undefined;
    }

    return data.response;
  }

  static async removeAllCache() {
    if (!("caches" in window)) return undefined;

    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }
}
