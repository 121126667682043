import { Form } from "react-bootstrap";
import { AICCArithmeticSelector } from "../../core/AICCArithmeticSelector";

export function AICCNumberInputs({
  selectedOperator,
  language,
  value,
  onChange,
  selectedRightArOp,
  rightArOpValue,
  onOpChange,
  onOpValueChange,
}) {
  if ((selectedOperator?.value ?? "").includes("_between")) {
    const minMax = value.split(",");

    return (
      <div className="row align-items-center g-0 gap-2">
        <div className="col-auto">
          <Form.Group className="form-group mb-0">
            <Form.Control
              type="number"
              placeholder={language?.numberBetweenMinPlaceholder ?? "Enter min"}
              value={minMax[0] ?? ""}
              onChange={(e) => {
                if (onChange) {
                  onChange(e.target.value, "min");
                }
              }}
              onWheel={(event) => {
                event.target.blur();
              }}
            />
          </Form.Group>
        </div>

        {/* <div className="col-auto">
          <AICCArithmeticSelector
            selectedOp={ariOp[0] ?? ""}
            value={ariOpVal[0] ?? ""}
            onOpChange={(val) => {
              onOpChange(val, "min");
            }}
            onChange={(val) => {
              onOpValueChange(val, "min");
            }}
          />
        </div> */}

        <div className="col-auto">
          <span
            className="d-inline-block"
            style={{
              marginRight: "6px",
              marginLeft: "6px",
            }}
          >
            {language?.betweenAndLabel ?? "and"}
          </span>
        </div>

        <div className="col-auto">
          <Form.Group className="form-group mb-0">
            <Form.Control
              type="number"
              placeholder={language?.numberBetweenMaxPlaceholder ?? "Enter max"}
              value={minMax[1] ?? ""}
              onChange={(e) => {
                if (onChange) {
                  onChange(e.target.value, "max");
                }
              }}
              onWheel={(event) => {
                event.target.blur();
              }}
            />
          </Form.Group>
        </div>

        {/* <div className="col-auto">
          <AICCArithmeticSelector
            selectedOp={ariOp[1] ?? ""}
            value={ariOpVal[1] ?? ""}
            onOpChange={(val) => {
              onOpChange(val, "max");
            }}
            onChange={(val) => {
              onOpValueChange(val, "max");
            }}
          />
        </div> */}
      </div>
    );
  }

  // else show normal number input
  return (
    <div className="row align-items-center g-0 gap-2">
      <Form.Group className="form-group mb-0 col-auto">
        <Form.Control
          type="number"
          placeholder={language?.numberInputPlaceholder ?? "Enter number"}
          value={value}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value);
            }
          }}
          onWheel={(event) => {
            event.target.blur();
          }}
        />
      </Form.Group>

      {/* <div className="col-auto">
        <AICCArithmeticSelector
          selectedOp={selectedRightArOp}
          value={rightArOpValue}
          onOpChange={(val) => {
            onOpChange(val);
          }}
          onChange={(val) => {
            onOpValueChange(val);
          }}
        />
      </div> */}
    </div>
  );
}
