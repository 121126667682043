import React from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Index from "./Index";
import Documents from "./Documents";
import GeographicalControl from "../features/selectedDataStream/geographical/GeographicalControl";
import NavBar from "./NavBar";
import Routing from "./Routing";
import RuleEngVersionOverview from "../features/selectedDataStream/RuleEngVersionOverview";
import SideBar from "./SideBar";
import AddGeographicalArea from "../features/selectedDataStream/geographical/AddGeographicalArea";
import FieldConfiguration from "./FieldConfiguration";
import AddField from "./AddField";
import UserList from "./UserList";
import AddUser from "./AddUser";
import UploadSchema from "./UploadSchema";
import ViewCompany from "./ViewCompany";
import AddRuleEngine from "../features/selectedDataStream/AddRuleEngine";
import CreateCompanyUserPage from "../features/company_users/pages/create_company_user_page";
import ManageSubscription from "./ManageSubscription";
import ContactAicc from "./ContactAicc";
import RuleEngineVersion from "../features/selectedDataStream/RuleEngineVersion";
import UserListPage from "../features/company_users/pages/userList.page";
import UpdateUser from "../features/profile/UpdateUser";
// import UpdateUser from "../features/profile/update_user";
import ChangePassword from "../features/auth/pages/change_password/ChangePassword";
import EnterOldPassword from "../features/auth/pages/change_password/EnterOldPassword";
import VeirfyResetPasswordOtp from "../features/auth/pages/change_password/VeirfyResetPasswordOtp";
import { IdleTimerProvider } from "react-idle-timer";
import { getPathPrefix } from "../utils/coreUtils";
import RoutingUserList from "../features/company_users/pages/RoutingUserList";
import FieldConfigJson from "../features/datastream/fieldConfig/FieldConfigJson";
import CreateVersions from "../features/selectedDataStream/CreateVersions";
import RulePriority from "../features/selectedDataStream/RulePriority";
import RulePrioritySelectable from "../features/selectedDataStream/RulePrioritySelectable";
import DataStreamProcess from "../features/datastream/DataStreamProcess";
import DataStreamReport from "../features/datastream/fieldConfig/DataStreamReport";
import CheckSelectedDataStream from "./CheckSelectedDataStream";

function MainIndex() {
  const navigate = useNavigate();

  const token = localStorage.getItem("authToken");

  if (!token) {
    return <Navigate to={getPathPrefix("login")} replace />;
  }

  const onPrompt = () => {
    // Fire a Modal Prompt
  };

  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    alert("Session has been time out. Please try login again");
    navigate(getPathPrefix("login"));
    localStorage.clear();
    window.location.reload();
  };

  const onActive = (event) => {
    // Close Modal Prompt
    // Do some active action
  };

  const onAction = (event) => {
    // Do something when a user triggers a watched event
  };

  return (
    <div>
      <NavBar />
      <SideBar />
      <Routes>
        <Route path="/dashboard" element={<Index />} />
        <Route exact path="/document" element={<Documents />} />

        <Route element={<CheckSelectedDataStream />}>
          <Route exact path="selected-datastream/routing" element={<Routing />} />
          <Route
            path="/selected-datastream/rule-engine/versions"
            element={<RuleEngineVersion />}
          />
          <Route
            path="/selected-datastream/rule-engine/versions/create"
            element={<CreateVersions />}
          />
          <Route
            path="/selected-datastream/rule-engine/versions/:versionId"
            element={<CreateVersions />}
          />

          <Route
            exact
            path="/selected-datastream/rule-engine/versionsOverview/:versionId"
            element={<RuleEngVersionOverview />}
          />

          <Route
            exact
            path="/selected-datastream/rule-engine/versions/:versionId/rule/add"
            element={<AddRuleEngine />}
          />

          <Route
            exact
            path="/selected-datastream/rule-engine/versions/:versionId/rule/:overviewId"
            element={<AddRuleEngine />}
          />

          <Route
            exact
            path="/selected-datastream/rule-engine/versions/:versionId/rule/priority"
            element={<RulePriority />}
          />
          <Route
            exact
            path="/selected-datastream/rule-engine/versions/:versionId/rule/priority-selectable"
            element={<RulePrioritySelectable />}
          />

          {/*for geographical*/}
          <Route
            exact
            path="/selected-datastream/geographical-control"
            element={<GeographicalControl />}
          />

          <Route
            exact
            path="/selected-datastream/geographical-control/create"
            element={<AddGeographicalArea />}
          />

          <Route
            exact
            path="/selected-datastream/geographical-control/:geographicId"
            element={<AddGeographicalArea />}
          />
          <Route
            exact
            path="/selected-datastream/report/:dataStreamConfigId"
            element={<DataStreamReport />}
          />
          <Route
            path="/selected-datastream/config-json/:dataStreamConfigId"
            element={<FieldConfigJson />}
          />
        </Route>

        <Route
          exact
          path="/datastream/field-config"
          element={<FieldConfiguration />}
        />
        <Route
          exact
          path="/datastream/processing"
          element={<DataStreamProcess />}
        />
        <Route path="/company-user/user-list" element={<UserListPage />} />
        <Route
          path="/company-user/routing-user-list"
          element={<RoutingUserList />}
        />
        <Route path="/company-user/create-user" element={<UpdateUser />} />
        <Route path="/company-user/update-user" element={<UpdateUser />} />
        <Route
          path="/company-user/view-user/:userId"
          element={<UpdateUser />}
        />
        <Route
          path="/company-user/update-user/:userId"
          element={<UpdateUser />}
        />
        {/* <Route exact path="/user-list" element={<UserList />} /> */}
        <Route path="/add-field" element={<AddField />} />
        <Route path="/add-user" element={<AddUser />} />
        <Route path="/upload-schema" element={<UploadSchema />} />
        <Route path="/view-company/view" element={<ViewCompany />} />
        <Route
          path="/view-company/subscription"
          element={<ManageSubscription />}
        />
        <Route path="/help-me/contact" element={<ContactAicc />} />
        {/* <Route
            exact
            path="/create-user"
            element={<CreateCompanyUserPage />}
          /> */}
        <Route path="/user-list" element={<UserListPage />} />
        <Route path="/change-old-password" element={<EnterOldPassword />} />
        <Route
          path="/change-password-otp-page"
          element={<VeirfyResetPasswordOtp />}
        />
        <Route path="/change-password-reset" element={<ChangePassword />} />
      </Routes>
    </div >
  );
}

export default MainIndex;
