import { useEffect, useMemo } from "react";
import { useRef } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import ReactSelect from "react-select";
import { AICCBuilderInitialConfig } from "../../constants/AICCBuilderConfig";
import AICCValueSource from "./AICCValueSource";
import AICCFieldSelector from "./AICCFieldSelector";

export function AICCArithmeticSelector({
  language,
  arithmeticOperators = AICCBuilderInitialConfig.arithmeticOperators,
  selectedOp,
  columnsWithGroup,
  allowValueSource = 1,
  selectValueSource,
  useColumnGroup,
  columnList,
  leftArOpFieldGroup,
  value,
  onOpChange,
  onChange,
  enabled,
  onCheckChanged,
  onChangeMultipleField
}) {
  const arithmeticOpRef = useRef();
  const leftArOpFieldSelectRef = useRef();
  useEffect(() => {
    if (!selectedOp) {
      if (arithmeticOpRef?.current) arithmeticOpRef?.current.clearValue();
    }
  }, [selectedOp]);
  // debugger

  const finalColumnList = useMemo(() => useColumnGroup ? columnsWithGroup.find((e) => "" + e?.value === "" + leftArOpFieldGroup)?.subFields ?? [] : columnList, [useColumnGroup, columnsWithGroup, columnList, leftArOpFieldGroup])
  return (
    <div
      className={`row g-0 gap-2 align-items-center h-100 aicc-ruleBuilder__rule--arithmetic-operators ${enabled ? "arith-active" : ""
        }`}
    >
      <div className="col-auto">
        <OverlayTrigger
          placement="auto"
          delay={{ show: 200 }}
          overlay={(props) => (
            <Tooltip id="button-tooltip" {...props}>
              {language?.arithmeticCheckboxTooltip ??
                "Apply arithmetic operation?"}
            </Tooltip>
          )}
        >
          <Form.Check
            checked={enabled}
            onChange={(e) => {
              if (onCheckChanged) {
                onCheckChanged(e.target.checked);
              }
            }}
          />
        </OverlayTrigger>
      </div>

      {enabled && (
        <div className="col-auto">
          <ReactSelect
            ref={arithmeticOpRef}
            options={arithmeticOperators}
            classNamePrefix={"select"}
            menuPosition={"fixed"}
            placeholder={
              language?.arithmeticSelectPlaceholder ?? "Select Operator"
            }
            value={arithmeticOperators.find((e) => e.value === selectedOp)}
            onChange={(option) => {
              if (onOpChange) {
                onOpChange("" + (option?.value ?? ""));
              }
            }}
          />
        </div>
      )}
      {enabled && selectedOp && allowValueSource && (<div className="col-auto aicc-ruleBuilder__rule--value-source">
        <AICCValueSource
          value={selectValueSource}
          language={language}
          onChange={(valueSource) => {
            if (onChangeMultipleField) {
              onChangeMultipleField({
                leftArOpValueSource: valueSource,
                leftArOpValue: ``,
                leftArOpFieldGroup: ``
              })
            }
          }}
        />
      </div>)}
      {
        selectValueSource === "field" ? (
          enabled && selectedOp &&
          <Form.Group className="form-group mb-0 col-auto row g-0 gap-2">
            <div className="col-auto aicc-ruleBuilder__rule--fields-group">
              {useColumnGroup && <AICCFieldSelector
                columnList={columnsWithGroup}
                value={leftArOpFieldGroup}
                placeholder={
                  language?.fieldStreamPlaceholder ?? "Select Field Stream"
                }
                noOptionsLabel={
                  language?.noFieldStreamOptions ?? "No field stream options"
                }
                onChange={(e) => {
                  if (onChangeMultipleField) {
                    onChangeMultipleField({
                      leftArOpFieldGroup: e.value,
                      leftArOpValue: ``,
                    })
                    setTimeout(() => {
                      if (leftArOpFieldSelectRef?.current) {
                        leftArOpFieldSelectRef.current.clearSelect();
                      }
                    }, 10);
                  }
                  // onFieldGroupChanged(e?.groupId)
                  // setTimeout(() => {
                  //   if (leftArOpFieldSelectRef?.current) {
                  //     leftArOpFieldSelectRef.current.clearSelect();
                  //   }
                  // }, 10);
                }}
              />}
            </div>
            <div className="col-auto aicc-ruleBuilder__rule--fields-group">
              <AICCFieldSelector
                columnList={finalColumnList}
                value={value}
                ref={leftArOpFieldSelectRef}
                placeholder={language?.fieldPlaceholder ?? "Select Field"}
                noOptionsLabel={language?.noFieldOptions ?? "No field options"}
                onChange={(e) => {
                  if (onChange && selectedOp) {
                    onChange(e?.value);
                  }
                  // setTimeout(() => {
                  //   if (fieldSelectRef?.current) {
                  //     fieldSelectRef.current.clearSelect();
                  //   }
                  // }, 10);
                }}
              />
            </div>
          </Form.Group>
        ) :
          (
            enabled && selectedOp &&
            <Form.Group className="form-group mb-0 col-auto">
              <Form.Control
                type="number"
                placeholder={language?.arithmeticValuePlaceholder ?? "Enter number"}
                value={value}
                onChange={(e) => {
                  if (onChange && selectedOp) {
                    onChange(e.target.value);
                  }
                }}
                onWheel={(event) => {
                  event.target.blur();
                }}
              />
            </Form.Group>
          )
      }

    </div>
  );
}
