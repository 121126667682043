const getUUID = () => window.crypto.randomUUID();

export default class AICCRuleBuilderUtils {
  static getUUID() {
    return getUUID();
  }

  static defaultQuery() {
    return {
      id: getUUID(),
    };
  }

  static opCommonFormat({ field, opVal, opDef, value }) {
    if (!field) return "";

    if (!opVal) return field;

    if (!value) return `${field} ${opDef}`;

    return `${field} ${opDef} ${value}`;
  }
}
