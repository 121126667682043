import { RULE_MESSAGE_TYPE } from "../../components/ruleEngine/CreateRuleSettingTab";

export default class RuleEngineConvert {
  static resMessageArrayToJson(messageList = []) {
    const messageJson = {};

    messageList.forEach((e) => {
      if (e.messageType === RULE_MESSAGE_TYPE.INTERNAL) {
        messageJson.message = e.message ?? "";
        messageJson.internalId = e.messageId;
        messageJson.internalDynamic = e.isDynamicMessageType ? "dynamic" : "static";
        messageJson.dynamicMessage = e.dynamicMessage ?? "";
      }

      if (e.messageType === RULE_MESSAGE_TYPE.EXTERNAL) {
        messageJson["message" + e.messageOrder] = e.message ?? "";
        messageJson["externalId" + e.messageOrder] = e.messageId;
        messageJson["dynamicMessage" + e.messageOrder] = e.dynamicMessage ?? "";
        messageJson["isDynamic" + e.messageOrder] = e.isDynamicMessageType ? "dynamic" : "static";
      }

      if (e.messageType === RULE_MESSAGE_TYPE.EPOST) {
        messageJson.emailMessage = e.message ?? "";
        messageJson.email = e.messageEmail ?? "";
        messageJson.emailMessageId = e.messageId;
        messageJson.emailDynamic = e.isDynamicMessageType ? "dynamic" : "static";
        messageJson.emailDynamicMessage = e.dynamicMessage ?? "";
      }

      if (e.isMessageActive) {
        messageJson.messageId = e.messageId;
        messageJson.messageType = e.messageType;

        if (e.messageOrder) {
          messageJson.order = e.messageOrder;
        }
      }
    });

    return messageJson;
  }
  static getMessageDetailsBasedOnSelection(messageDetails) {
    const list = [];

    list.push({
      message: messageDetails.message ?? "",
      dynamicMessage: messageDetails.dynamicMessage ?? "",
      messageId: messageDetails?.internalId ?? 0,
      messageType: RULE_MESSAGE_TYPE.INTERNAL,
      isDynamicMessageType: messageDetails.internalDynamic === "dynamic",
      isMessageActive:
        messageDetails.messageType === RULE_MESSAGE_TYPE.INTERNAL,
    });

    for (let i = 0; i < 3; i++) {
      list.push({
        message: messageDetails["message" + (i + 1)] ?? "",
        dynamicMessage: messageDetails["dynamicMessage" + (i + 1)] ?? "",
        messageId: messageDetails["externalId" + (i + 1)] ?? 0,
        messageOrder: i + 1,
        messageType: RULE_MESSAGE_TYPE.EXTERNAL,
        isDynamicMessageType: messageDetails["isDynamic" + (i + 1)] === "dynamic",
        isMessageActive:
          messageDetails.messageType === RULE_MESSAGE_TYPE.EXTERNAL &&
          messageDetails.order === i + 1,
      });
    }

    list.push({
      messageEmail: messageDetails.email ?? "",
      dynamicMessage: messageDetails.emailDynamicMessage ?? "",
      message: messageDetails.emailMessage ?? "",
      messageId: messageDetails.emailMessageId ?? 0,
      messageType: RULE_MESSAGE_TYPE.EPOST,
      isDynamicMessageType: messageDetails.emailDynamic === "dynamic",
      isMessageActive: messageDetails.messageType === RULE_MESSAGE_TYPE.EPOST,
    });

    return list;
  }
}
