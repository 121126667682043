import React, { useContext, useState } from "react";

export const QueryContext = React.createContext({
  query: [],
  setQuery: () => { },
});

export const QueryContextProvider = (props) => {
  const [query, setQuery] = useState([]);

  return (
    <QueryContext.Provider
      value={{
        query,
        setQuery,
      }}
      {...props}
    />
  );
};

export const useQueryContext = () => {
  const queryContextProps = useContext(QueryContext);

  return {
    ...queryContextProps,
  };
};
