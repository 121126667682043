import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import pinApi from "../../../services/apis/pinApi";
import ToastUtils from "../../../utils/ToastUtils";
import PrimaryButton from "../../Form/Buttons/PrimaryButton";
import CustomModal from "../CustomModal";

// for resend Otp
const minutes = 0;
const seconds = 45;

export default function GeneratePinOTPModal({
  onSuccess,
  asModal,
  show,
  onClose,
}) {
  const [t, i18n] = useTranslation();

  const [[mins, secs], setTime] = useState([minutes, seconds]);
  const [verificationOtp, setVerificationOtp] = useState("");
  const [isSendingOtp, setIsSendingOtp] = useState(false);

  const tick = () => {
    if (secs > 0) {
      setTime([mins, secs - 1]);
    }
  };

  useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  }, [mins, secs]);

  const reset = () => setTime([parseInt(minutes), parseInt(seconds)]);

  useEffect(() => {
    setVerificationOtp("");
    if (show) {
      reset();
    }
  }, [show]);

  const verifyOtp = async () => {
    try {
      const response = await pinApi.generatePin({
        companyId: localStorage.getItem("companyId"),
        otp: verificationOtp,
      });

      if (response.data.pin) {
        reset();

        if (onSuccess) {
          onSuccess(response.data.pin);
        }
      } else if (response.data.code) {
        ToastUtils.serverError(t(`statusCodes.${response.data.code}`));
      }
    } catch (error) {}
  };

  const resendOtp = async () => {
    setIsSendingOtp(true);
    try {
      const response = await pinApi.generateAccessToken({
        companyId: localStorage.getItem("companyId"),
      });
      reset();
    } catch (error) {}
    setIsSendingOtp(false);
  };

  const viewToRender = () => {
    return (
      <div className="otp-input text-center">
        <h6 className="text-primary-app fw-bold">
          {t("generatePinVerifyModal.checkEmailLabel")}
        </h6>
        <h6 className="text-primary-app">
          {t("generatePinVerifyModal.enterOtpText")}
        </h6>

        <div className="justify-content-center row py-4">
          <OtpInput
            containerStyle={{
              display: "flex",
              justifyContent: "space-between",
              maxWidth: "310px",
            }}
            value={verificationOtp}
            onChange={setVerificationOtp}
            numInputs={6}
            className="input-style"
            shouldAutoFocus
          />
        </div>

        <div className="form-group text-center">
          {`${secs.toString().padStart(2)}` > 0 ? (
            <b style={{ color: "#004e5c" }}>
              {mins.toString().padStart(2, "0")} :{" "}
              {secs.toString().padStart(2, "0")}
            </b>
          ) : isSendingOtp ? (
            <b className="cursor-pointer">{t("CommonKeys.sendingText")}</b>
          ) : (
            <b
              className="cursor-pointer"
              onClick={() => {
                resendOtp();
              }}
            >
              {t("CommonKeys.resendOtpText")}
            </b>
          )}
        </div>

        <div className="justify-content-center row">
          <PrimaryButton
            onClick={verifyOtp}
            disabled={!verificationOtp || verificationOtp.length < 6}
            title={t("CommonKeys.verifyButtonTitle")}
          />
          <PrimaryButton
            title={t("CommonKeys.cancelButtonTitle")}
            className="ms-3"
            onClick={onClose}
          />
        </div>
      </div>
    );
  };

  if (!asModal) {
    return viewToRender();
  }

  return (
    <CustomModal
      show={show}
      handleClose={onClose}
      title={t("generatePinVerifyModal.modalTitle")}
      body={viewToRender()}
      centered
    />
  );
}
