import { jsonToQuery } from "../../utils/ServicesUtils";
import { doFetch, REQUEST_CONTENT_TYPE, REQUEST_METHODS } from "../fetcher";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  saveOrUpdateGeographicalAreaControl: (data) =>
    doFetch("/geographicalAreaControl/saveOrUpdateGeographicalAreaControl", {
      method: REQUEST_METHODS.POST,
      body: data,
      // showToast: false,
    }),
  getAllGeographicalAreaControl: (query) =>
    doFetch(
      `/geographicalAreaControl/getAllGeographicalAreaControl${jsonToQuery(
        query
      )}`
    ),
  getAllGeographicalAreaControlByDSConfigId: (query) =>
    doFetch(
      `/geographicalAreaControl/getAllGeographicalAreaControlByDSConfigId${jsonToQuery(
        query
      )}`
    ),
  getGeographicalAreaControlById: (query) =>
    doFetch(
      `/geographicalAreaControl/getGeographicalAreaControlById${jsonToQuery(
        query
      )}`
    ),
  deleteGeographicalAreaControlById: (query) =>
    doFetch(
      `/geographicalAreaControl/deleteGeographicalAreaControlById${jsonToQuery(
        query
      )}`,
      {
        method: REQUEST_METHODS.DELETE,
      }
    ),
};
