import {parsePhoneNumber} from "react-phone-number-input";

export function getPhoneNumberJson(value) {
    const defaultJson = {
        code: "",
        nationalNumber: "",
        internationalNumber: "",
        valid: false,
    };

    if (!value) {
        return defaultJson;
    }

    if (value.trim() === "") {
        return defaultJson;
    }

    const phoneJson = parsePhoneNumber(value);

    if (phoneJson) {
        defaultJson.code = "+" + phoneJson.countryCallingCode;
        defaultJson.internationalNumber = phoneJson.number;
        defaultJson.nationalNumber = phoneJson.nationalNumber;
        defaultJson.valid = phoneJson.isValid();
    }

    return defaultJson;
}