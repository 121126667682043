/* eslint-disable import/no-anonymous-default-export */
import { jsonToQuery } from "../../utils/ServicesUtils";
import { doFetch, REQUEST_CONTENT_TYPE, REQUEST_METHODS } from "../fetcher";

export default {
  getCompanyUserDetailsById: (query) =>
    doFetch(`/companyUser/getCompanyUserDetailsById${jsonToQuery(query)}`, {
      method: REQUEST_METHODS.GET,
    }),
  getAllCompanyUser: (query) =>
    doFetch(`/companyUser/getAllCompanyUser${jsonToQuery(query)}`, {
      method: REQUEST_METHODS.GET,
    }),
  saveOrUpdateCompanyUser: (body) =>
    doFetch(`/companyUser/saveCompanyUser`, {
      body,
      method: REQUEST_METHODS.POST,
      contentType: REQUEST_CONTENT_TYPE.MULTIPART,
    }),
  saveCompanyProfile: (body) =>
    doFetch(`/companyUser/saveCompanyUser`, {
      body,
      method: REQUEST_METHODS.POST,
      contentType: REQUEST_CONTENT_TYPE.MULTIPART,
    }),
  updateCompanyProfile: (body) =>
    doFetch(`/companyUser/updateCompanyUser`, {
      body,
      method: REQUEST_METHODS.POST,
      contentType: REQUEST_CONTENT_TYPE.MULTIPART,
    }),
  saveCompanyUser: (body) =>
    doFetch(`/companyUser/saveCompanyUser`, {
      body,
      method: REQUEST_METHODS.POST,
      contentType: REQUEST_CONTENT_TYPE.MULTIPART,
    }),
  updateCompanyUser: (body) =>
    doFetch(`/companyUser/updateCompanyUser`, {
      body,
      method: REQUEST_METHODS.POST,
      contentType: REQUEST_CONTENT_TYPE.MULTIPART,
    }),
  generateOtpChangePassword: (body) =>
    doFetch(`/companyUser/generateOtpForChangePassword`, {
      body,
      method: REQUEST_METHODS.POST,
      // contentType: REQUEST_CONTENT_TYPE.MULTIPART,
    }),
  otpVerificationChangePassword: (body) =>
    doFetch(`/companyUser/otpVerificationForchangePassword`, {
      body,
      method: REQUEST_METHODS.POST,
      // contentType: REQUEST_CONTENT_TYPE.MULTIPART,
    }),
  changePassword: (body) =>
    doFetch(`/companyUser/changePassword`, {
      body,
      method: REQUEST_METHODS.POST,
    }),
    deleteCompanyUserById: (query) =>
    doFetch(`/companyUser/deleteCompanyUserById${jsonToQuery(query)}`, {
      method: REQUEST_METHODS.DELETE,
    }),
    saveOrUpdateGeographicalAreaControl: (data) =>
    doFetch("/companyUser/saveOrUpdateCompanyUser", {
      method: REQUEST_METHODS.POST,
      body: data,
      isAdmin: false,
    }),
};
