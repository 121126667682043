import { Form } from "react-bootstrap";

export function AICCDateTimeInputsValue({
  selectedOperator,
  language,
  value,
  onChange,
}) {
  if ((selectedOperator?.value ?? "").includes("_between")) {
    const minMax = value.split(",");

    return (
      <div className="d-flex align-items-center">
        <Form.Group className="form-group mb-0">
          <Form.Control
            type="date"
            value={minMax[0] ?? ""}
            onChange={(e) => {
              if (onChange) {
                onChange(e.target.value, "min");
              }
            }}
          />
        </Form.Group>
        <span
          className="d-inline-block"
          style={{
            marginRight: "6px",
            marginLeft: "6px",
          }}
        >
          {language?.betweenAndLabel ?? "and"}
        </span>
        <Form.Group className="form-group mb-0">
          <Form.Control
            type="date"
            value={minMax[1] ?? ""}
            onChange={(e) => {
              if (onChange) {
                onChange(e.target.value, "max");
              }
            }}
          />
        </Form.Group>
      </div>
    );
  }

  // else show normal input with date time picker
  return (
    <Form.Group className="form-group mb-0">
      <Form.Control
        type="date"
        value={value}
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.value);
          }
        }}
      />
    </Form.Group>
  );
}
