import { useEffect } from "react";
import { useMemo, useState, useCallback } from "react";
import { AICCBuilderInitialConfig } from "../constants/AICCBuilderConfig";
import AICCRuleBuilderUtils from "../utils/AICCRuleBuilderUtils";
import AICCRule from "./AICCRule";
import AICCRuleGroupHeader from "./core/AICCRuleGroupHeader";
import "./ruleBuilder.scss";

export default function AICCRuleBuilder({
  query = [AICCRuleBuilderUtils.defaultQuery()],
  onChangeQuery,
  useFieldGroup = false,
  fieldsWithGroup = [],
  fields = [],
  operators,
  language = AICCBuilderInitialConfig.language,
  allowValueSource = false,
  allowArithmetic = false,
}) {
  const ruleChangeCallback = useCallback(
    (index, item) => {
      const temp = Array.from(query);

      const foundIndex = temp.findIndex((e) => e.id === item.id);

      if (foundIndex >= 0) {
        temp[foundIndex] = {
          ...temp[foundIndex],
          ...item,
        };
      }
      if (onChangeQuery) {
        onChangeQuery(temp);
      }
    },
    [onChangeQuery, query]
  );

  const finalQuery = useMemo(() => query.filter((e) => !e.isDeleted), [query]);

  return (
    <div className="aicc-ruleBuilder p-3 border rounded-3">
      <AICCRuleGroupHeader
        language={language}
        onAdd={() => {
          const temp = Array.from(query);
          temp.splice(query.length, 0, AICCRuleBuilderUtils.defaultQuery());

          if (onChangeQuery) {
            onChangeQuery(temp);
          }
        }}
      />
      <div className="aicc-ruleBuilder_rule-list">
        {finalQuery.map((item, index) => {
          return (
            <AICCRule
              language={language}
              allowValueSource={allowValueSource}
              allowArithmetic={allowArithmetic}
              key={item.id}
              data={item}
              useColumnGroup={useFieldGroup}
              columnsWithGroup={fieldsWithGroup}
              columnList={fields}
              operatorList={operators}
              // removeVisible={isRemoveVisible}
              removeVisible={true}
              midOperatorVisible={index !== finalQuery.length - 1}
              onChange={(rule) => {
                ruleChangeCallback(index, rule);
              }}
              onRemove={() => {
                const temp = Array.from(query);

                const originalIndex = temp.findIndex((e) => e.id === item.id);

                const previousObjId =
                  index > 0 ? finalQuery[index - 1]?.id : undefined;

                const mainPreviousObjIndex = temp.findIndex(
                  (ele) => ele.id === previousObjId
                );

                if (originalIndex > -1) {
                  if (mainPreviousObjIndex >= 0) {
                    temp[mainPreviousObjIndex].midOperator =
                      "" + (item.midOperator ?? "and");
                  }
                  if (temp[originalIndex].expressionId !== undefined) {
                    temp[originalIndex].isDeleted = true;
                  } else {
                    temp.splice(originalIndex, 1);
                  }
                }

                if (onChangeQuery) {
                  onChangeQuery(temp);
                }
              }}
              onAdd={() => {
                const temp = Array.from(query);

                const foundedIndex = query.findIndex(
                  (ele) => ele.id === finalQuery[finalQuery.length - 1]?.id
                );

                if (foundedIndex > -1) {
                  temp[foundedIndex].midOperator = "and";
                }

                temp.push(AICCRuleBuilderUtils.defaultQuery());

                // console.log("temp", temp);

                if (onChangeQuery) {
                  onChangeQuery(temp);
                }
              }}
            />
          );
        })}
      </div>
    </div>
  );
}
