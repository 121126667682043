import { useRef, useState } from "react";
import ArrowIcon from "./double-down-arrow.svg";

export function Accordion(props) {
  const [setActive, setActiveState] = useState("");
  const { title, children } = props;

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
  }

  return (
    <div className="accordion__section">
      <div className={`accordion ${setActive}`}>
        {/* <a
          style={{ color: "#004e5c", width: "100%" }}
          onClick={props.onClick}
        >
          <p className="accordion__title">{title}</p>
        </a> */}
        <div className="accordion__title" onClick={props.onClick}>{title}</div>
        <button
          onClick={toggleAccordion}
          className="p-0"
          style={{ border: "none", background: "#f0f6f7", minWidth: "25px" }}
        >
          <img
            src={ArrowIcon}
            className={`arrow-icon ${setActive === "" ? "" : "rotate"}`}
            alt="arrow-logo"
          />
        </button>
      </div>
      <div
        ref={content}
        style={{ display: setActive === "active" ? "block" : `none` }}
        className="accordion__content"
      >
        {children}
      </div>
    </div>
  );
}
