import React from "react";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import RightPanelHeader from "../../components/rightPanelHeader.component";
import TextInput from "../../../../components/Form/Inputs/TextInput";
import PrimaryButton from "../../../../components/Form/Buttons/PrimaryButton";
import "./ChangePass.css";
import { getPathPrefix } from "../../../../utils/coreUtils";

const EnterOldPassword = () => {
  const [t, i18n] = useTranslation("common");
  const navigate = useNavigate();

  const initialFormValues = {
    oldPassword: "",
  };

  const handleSubmit = (takeValue) => {
    console.log("====================================");
    console.log(takeValue);
    console.log("====================================");
    navigate(getPathPrefix() + `change-password-otp-page`);
  };

  return (
    <section className="page main-signin-wrapper container-layout align-items-center main-password-change">
      <div className="row bg-white card-view">
        <div className="col-lg-12">
          <div className="form-container">
            <RightPanelHeader
              title={t("language.reset_password")}
              subTitle={`Please enter your old password and proceed`}
            />
            <Formik
              initialValues={initialFormValues}
              validateOnMount
              className="mt-2"
              onSubmit={(values) => handleSubmit(values)}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isValid,
                isSubmitting,
                dirty,
                ...props
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Field
                    component={TextInput}
                    label={"Old Password"}
                    id="oldPassword"
                    name="oldPassword"
                    placeholder="Old Password"
                  />

                  <div className="row justify-content-end mb-3">
                    <PrimaryButton
                      type="submit"
                      title={t("language.submit")}
                      className="btn btn-green w-auto w-75"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {/* <ToastComponent /> */}
    </section>
  );
};

export default EnterOldPassword;
