import { useTranslation } from "react-i18next";
import CustomModal from "../CustomModal";

export default function ViewGeneratedPin({
  companyPin,
  asModal,
  show,
  onClose,
}) {
  const [t] = useTranslation();

  const viewToRender = () => {
    return (
      <div className="text-center">
        <h4 className="text-primary-app fw-bold">
          {t("viewGeneratedPinModal.secureCompanyPin")}
        </h4>
        <h6 className="text-primary-app">{t("viewGeneratedPinModal.doNotShare")}</h6>
        <h1 className="text-black fw-bolder my-3">{companyPin}</h1>
      </div>
    );
  };
  if (!asModal) {
    return viewToRender();
  }

  return (
    <CustomModal
      show={show}
      handleClose={onClose}
      body={viewToRender()}
      centered
      onOk={onClose}
    />
  );
}
